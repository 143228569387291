.right-now {
  margin-bottom: 324px;
  @include md {
    margin-bottom: 200px; }
  &__inner {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 960px; }
  &__text-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 56px;
    @include md {
      display: block;
      text-align: center;
      margin-bottom: 50px; }
    @include xxs {
      margin-bottom: 35px; } }
  &__arrow {
    margin-bottom: 80px;
    @include md {
      margin-bottom: 60px;
      max-width: 100%; }
    @include xxs {
      margin-bottom: 35px; } }
  &__big-text {
    margin-right: 26px;
    @include md {
      margin-right: 0;
      margin-bottom: 30px; }
    @include xxs {
      font-size: 35px; } }
  &__small-text {
    span {
      @include md {
        display: inline; } }
    strong {
      @include md {
        display: none; } }
    @include xxs {
      font-size: 16px; } }
  &__mob-text {
    display: none;
    @include md {
      display: block; } } }


