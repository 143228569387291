.form {
  $this: &;
  &__line {
    display: flex;
    align-items: flex-start;
    font-size: 0;
    @include md {
      flex-wrap: wrap;
      justify-content: center; }
    &--vertical {
      align-items: center; }
    &--center {
      justify-content: center; }
    &--mb-30 {
      margin-bottom: 30px; }
    &--tablet-spec {
      @include md {
        flex-wrap: nowrap;
        justify-content: flex-start; }
      @include sm {
         flex-wrap: wrap;
         justify-content: center; }
      .form__field {
        @include sm {
          width: 100%;
          margin-right: 0; } }
      &:first-of-type {
        .form__field {
          @include md {
            width: calc(50% - 5px); }
          @include sm {
            width: 100%; } } } } }
  &__field {
    position: relative;
    height: 79px;
    margin-right: 10px;
    margin-bottom: 10px;
    @include l {
      height: 64px; }
    @include xxs {
      margin-right: 0;
      height: 50px; }
    &--mr-0 {
      margin-right: 0; }
    &--w-406 {
      width: 406px;
      @include md {
        width: calc(50% - 5px); }
      @include xxs {
        width: 100%; } }
    &--w-395 {
      width: 395px;
      @include md {
        width: calc(50% - 5px);
        margin-right: 0; }
      @include xxs {
        width: 100%; } }
    &--w-364 {
      width: 364px; }
    &--6-66 {
      width: 66%; }
    &--w-177 {
      width: 177px;
      @include xxxs {
        width: 46.57%; } }
    &--w-190 {
      width: 190px;
      @include xxxs {
        width: 50%; } }
    &--w-300 {
      width: 300px; }
    &--w-736 {
      width: 736px; }
    &--mr-big {
      margin-right: 22px; }
    &--w-max {
      width: 100%; }
    &--mb-20 {
      margin-bottom: 20px; }
    &--icon {
      #{$this}__input {
        padding: 10px 60px 10px 30px;
        @include xxs {
          padding: 10px 45px 10px 15px; } }
      i {
        position: absolute;
        z-index: 22;
        top: 50%;
        right: 30px;
        @include gpu;
        transform: translateY(-50%);
        display: inline-block;
        cursor: pointer;
        @include xxs {
          right: 15px; } }
      svg {
        font-size: 21px;
        fill: $red; }
      &-disable {
        i {
          pointer-events: none; } } }
    &--sm-height {
      height: 64px; }
    &.is-error {
      #{$this}__input {
        box-shadow: inset 0 0 13px rgba(234, 36, 46, 0.06);
        border-color: $red4; } } }
  &__input {
      display: block;
      width: 100%;
      height: 100%;
      padding: 10px 30px 10px 30px;
      border: 1px solid $white;
      border-radius: 7px;
      background-color: $white;
      font-size: 16px;
      transition: border-color 0.3s;
      @include mlight;
      color: $gray;
      @include placeholder {
        color: $gray; }
      @include l {
        padding: 10px 30px 10px 30px; }
      @include xxs {
        padding: 10px 15px 10px 15px; }
      &--font-big {
        font-size: 26px; }
      &--border-gray {
        border-color: $gray5; } }
  &__checkbox {
    text-align: center;
    transition: opacity 0.3s;
    @include hover {
      opacity: 0.8; }
    input[type="checkbox"] {
      display: none;
      &:checked {
        & + label {
          svg {
            opacity: 1; }
          i {
            &:before {
              opacity: 0; } } } } }
    label {
      display: inline-block;
      text-align: center;
      font-size: 0;
      cursor: pointer;
      user-select: none; }
    i {
      position: relative;
      display: inline-block;
      vertical-align: top;
      font-size: 0;
      margin-right: 6px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 2px solid $white {
         transition: opacity 0.3s; } } }
    span {
      border-bottom: 1px dashed $white;
      font-size: 18px;
      color: $white;
      @include mreg; }
    svg {
      width: 22px;
      height: 22px;
      fill: $red;
      transition: opacity 0.3s;
      opacity: 0; }
    &--dark {
      i {
        @include md {
          top: -3px; }
        &:before {
          border-color: rgba($black-main, 0.3); } }
      span {
        color: $black-main;
        border-bottom: none;
        @include md {
          font-size: 14px; } }
      svg {
        fill: $black-main; } } }
  &__link {
    color: $red4;
    border-bottom: 1px dashed $red4;
    @include mreg;
    font-size: 18px;
    @include md {
      font-size: 14px; } }
  &__spec-group {
    position: relative;
    width: 380px;
    @include md {
      margin-left: 12px;
      margin-bottom: 50px; }

    @include xxxs {
      width: 100%;
      margin-left: 0; }
    #{$this}__field {
      transition: opacity 0.3s;
      @include xxs {
        &:first-of-type {
          margin-right: 10px; } } }
    #{$this}__checkbox {
      i {
        top: 2px; } }
    &.is-checked {
      #{$this}__field {
        pointer-events: none;
        user-select: none;
        opacity: 0.8;
        cursor: default; } }
    &-decor-line {
      position: relative;
      left: -6px;
      margin-bottom: 12px;
      width: calc(100% + 12px);
      user-select: none; }
    .sssl {
      & > span {
        border-color: transparent;
        &:before {
          opacity: 0; } } } }
  &__spec-block {
    height: auto; }
  &__btn-wrap {
    &--mb-60 {
      margin-bottom: 60px;
      @include md {
        margin-bottom: 30px; } } } }

.form-block {
  padding: 10px 10px 10px 10px;
  font-size: 16px;
  @include mreg;
  color: $black-main;

  &__head {
    margin-bottom: 10px; }
  &__list {
    max-height: 150px;
    overflow-y: auto;
    list-style-type: none;
    @include counter(1, '. ');
    li {
      font-size: 16px;
      &:not(:last-of-type) {
        margin-bottom: 10px; } }
    a, button {
      display: inline;
      vertical-align: top;
      // margin-left: 3px
      border: none;
      background: none;
      color: $red4;
      border-bottom: 1px dashed $red;
      @include mlight;
      text-align: left; }
    button {
      appearance: none;
      font-size: 16px; } }
  &__field {
    font-size: 14px;
    span {
      padding-right: 3px; } } }
