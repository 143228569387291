.route-delivery {
  position: relative;
  margin-bottom: 350px;
  @include md {
    margin-bottom: 280px; }
  @include sm {
    margin-bottom: 200px; }
  &__inner {}
  &__sub-title {
    @include md {
      margin-bottom: 20px; } }
  &__sub-text {
    text-align: center;
    margin-bottom: 92px;
    @include md {
      margin-bottom: 45px; } }
  .number-plate {
    @include md {
      margin-left: 20px; }
    @include sm {
      margin-right: 0; }
    &__imgs {
      @include md {
        top: 30%; }
      @include sm {
        top: 40%; }
      img {
        @include lm {
          max-width: 100vw;
          width: auto; }
        @include sm {
          width: 130vw; } } } } }

