.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 4;
  padding: 48px 0 0;
  height: 109px;
  @include xxs {
    padding: 30px 0 0;
    height: 78px; }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  &__logo {
    display: inline-block;
    font-size: 0;
    img {
      width: 148px;
      height: 47px; } }
  &__wrap {
    flex-grow: 1;
    &-inner {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
      @include md {
        position: absolute;
        z-index: 49;
        display: none;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        min-height: 650px;
        padding: 53px 20px 30px;
        background: linear-gradient($white, rgba($white, 0.8) 60%, rgba($white, 0)); }
      @include xxs {
        padding: 95px 20px 30px; }
      &.is-active {
        @include md {
          display: block; } } } }
  &__spec-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    @include md {
      max-width: 550px;
      margin-left: auto;
      margin-right: auto; }
    @include sm {
      max-width: 100%; }
    &:first-of-type {
      @include md {
        margin-bottom: 50px; }
      @include sm {
        padding-right: 50px; }
      @include xxs {
        justify-content: center;
        padding-right: 0;
        margin-bottom: 40px; } }
    &:nth-of-type(2) {
      @include xxs {
        flex-wrap: wrap; } } }
  &__menu {
    border-left: 2px dotted rgba($black, 0.4);
    padding-left: 21px;
    margin-left: 25px;
    @include md {
      line-height: 1.5;
      margin-left: 30px;
      padding-left: 30px; }
    @include sm {
      border: none;
      padding: 0; }
    @include xxs {
      width: 100%;
      margin-left: 0;
      margin-top: 40px;
      text-align: center; }
    li {
      display: inline-block;
      vertical-align: top;
      &:not(:last-child) {
        margin-right: 25px; }
      a {
        @include mreg;
        font-size: 16px;
        transition: color 0.3s;
        @include hover {
          color: $red4; } } } }
  &__connect-btn {
    margin-left: 35px;
    @include mreg;
    font-size: 16px;
    @include md {
      margin-left: 2px; } }
  &__phone {
    font-size: 30px;
    transition: color 0.3s;
    display: flex;
    align-items: flex-end;
    line-height: 1;
    @include md {
      position: relative;
      display: block;
      margin-right: 36px;
      padding-right: 36px;
      border-right: 2px dotted rgba(0,0,0,.4);
      line-height: 1; }
    @include xxs {
      font-size: 18px;
      padding-right: 20px;
      margin-right: 20px; }
    span {
      position: relative;
      bottom: 3px;
      font-size: 20px;
      margin-right: 3px;
      @include md {
        margin-right: 0;
        bottom: 1px; }
      @include xxs {
        bottom: 0;
        font-size: 14px; } }
    @include hover {
      color: $red4; } }
  &__mob-btn {
    display: none;
    font-size: 16px;
    color: $red3;
    border-bottom: 1px dashed $red3;
    @include md {
      display: inline-block; }
    @include xxs {
      font-size: 14px; } }
  .user-btn {
    margin-left: 16px;
    @include md {
      margin-left: 22px; } }
  a {
    color: $blue; }
  &__burger {
    display: none;
    position: relative;
    z-index: 50;
    margin-left: auto;
    @include md {
      display: block;
      width: 48px;
      height: 38px; }
    @include xxs {
      width: 44px;
      height: 34px; }
    &:before, &:after, span {
      position: absolute;
      left: 0;
      height: 6px;
      width: 100%;
      border-radius: 3px;
      background: $red4;
      transition: transform 0.3s, opacity 0.3s, width 0.3s;
      @include xxs {
        height: 4px; } }
    &:before {
      content: '';
      top: 0;
      width: 38px;
      transform-origin: 0 0; }
    &:after {
      content: '';
      left:  auto;
      right: 0;
      bottom: 0;
      width: 38px;
      transform-origin: 0% 100%; }
    span {
      top: 50%;
      transform: translateY(-50%); }
    &.is-active {
      span {
        opacity: 0; }
      &:before {
        transform: rotate(44deg);
        width: 48px;
        @include xxs {
          width: 44px; } }
      &:after {
        transform: rotate(-44deg);
        width: 48px;
        @include xxs {
          width: 44px; } } } }
  .js-hero-content-elem {
    @include md {
      opacity: 1; } } }


