.list {
  ul {}
  li {
    position: relative;
    padding-left: 17px;
    font-size: 15px;
    letter-spacing: 0.4px;
    @include sm {
      padding-left: 0; }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      bottom: 3px;
      width: 5px;
      border-radius: 3px;
      background-color: $red6;
      @include sm {
        display: none; } }
    &:not(:last-child) {
      margin-bottom: 15px;
      @include sm {
        margin-bottom: 12px; } } }
  &--max-460 {
    max-width: 460px;
    @include sm {
      max-width: 100%; } } }
