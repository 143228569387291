.main-delivery {
  margin-top: 35vw;
  @include k2min {
    margin-top: 44vw; }
  @include md {
    margin-top: 19vw; }
  @include is-phone {
    @include md {
      margin-top: 12vw; }
    @include sm {
      margin-top: 10vw; }
    @include xxs {
      margin-top: 7vw; } }
  &__inner {
    display: flex;
    align-items: flex-end;
    min-height: 230px;
    margin-bottom: 180px;
    @include k2min {
      margin-bottom: 10.5vw; }
    @include md {
      margin-bottom: 63vw; }
    @include is-phone {
      @include md {
        margin-bottom: 49vw; }
      @include r(870) {
        margin-bottom: 48vw; }
      @include r(820) {
        margin-bottom: 51vw; }
      @include sm {
        margin-bottom: 56vw; }
      @include r(699) {
        margin-bottom: 58vw; }
      @include r(619) {
        margin-bottom: 61vw; }
      @include r(549) {
        margin-bottom: 68vw; }
      @include r(489) {
        margin-bottom: 72vw; }
      @include xxxs {
        margin-bottom: 76vw; }
      @include r(410) {
        margin-bottom: 79vw; }
      @include r(390) {
        margin-bottom: 85vw; }
      @include extra-xs {
        margin-bottom: 90vw; } } }
  &__text {
    @include md {
      font-size: 50px; }
    @include is-phone {
      @include md {
        font-size: 5.5vw; }
      @include xxs {
        font-size: 7vw; }
      @include xxxs {
        font-size: 8vw; } } }
  .waves {
    @include md {
      transform-origin: 50% 50%;
      top: 0;
      transform: translateY(-82px); }
    @include is-phone {
      @include md {
        transform: translateY(-3.5vw); }
      @include sm {
        transform: translateY(-1.5vw); }
      @include xxs {
        transform: translateY(3vw); }
      @include r(399) {
        transform: translateY(8vw); } }
    &__inner {
      @include is-phone {
        @media only screen and (min-width: "430px") and  (orientation: portrait) {
          left: 54%; } } } } }
.delivery-nav {
  $this: &;
  position: relative;
  top: -20px;
  margin-bottom: 230px;
  @include md {
    margin-bottom: 210px; }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
  &__item {
    text-align: center;
    &:nth-of-type(1), &:nth-of-type(2) {
      @include sm {
        width: 20%; } }
    @include sm {
      width: 30%; } }
  &__link {
    position: relative;
    display: inline-block;
    padding: 0 25px;
    transition: opacity 0.3s;
    @include md {
      padding: 0 17px; }
    @include sm {
      display: inline-block;
      padding: 0 5px; }
    @include extra-xs {
      padding:  0 7px; }
    &:not(.is-active) {
      @include hover {
        opacity: 0.5; } }
    span {
      display: block;
      @include mreg;
      font-size: 18px;
      color: $red3;
      transition: color 0.3s;
      @include md {
        line-height: 1; }
      @include sm {
        max-width: 120px;
        min-height: 33px;
        margin: 0 auto;
        font-size: 16px; }
      @include xxxs {
        font-size: 14px; }
      @include extra-xs {
        font-size: 12px; }
      &.delivery-nav__hidden-tablet {
        @include md {
          display: none; } }
      &.delivery-nav__show-tablet {
        display: none;
        @include md {
          display: block; } } }
    &.is-active {
      #{$this}__icon {
        svg {
          fill: $red4; } }
      #{$this}__decor {
        opacity: 1; }
      span {
        color: $red4; } } }
  &__icon {
    display: block;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    text-align: center;
    @include md {
      margin-bottom: 6px; }
    @include extra-xs {
      height: 30px; }
    svg {
      font-size: 40px;
      fill: $red3;
      transition: fill 0.3s;
      @include xxs {
        font-size: 34px; }
      @include extra-xs {
        font-size: 30px; } } }
  &__decor {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 100%);
    height: 20px;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    @include sm {
      bottom: 18px; }
    @include xxxs {
      bottom: 21px; }
    @include extra-xs {
      bottom: 23px;
      height: 16px; }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 200%;
      border: 1px dotted $red4;
      border-radius: 30px; } }
  .bg-wave {
    top: 30%;
    height: 318px;
    @include r(549) {
      top: 12%; }
    &__inner {
      width: 100%;
      opacity: 1; } } }


