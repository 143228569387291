.descr-block {
  $this: &;
  position: relative;
  z-index: 1;
  margin-bottom: 365px;
  @include md {
    margin-bottom: 250px; }
  @include sm {
    margin-bottom: 200px; }
  &__l {
    position: relative;
    z-index: 1; }
  &__bg-wave {
    height: 1330px;
    @include md {
      height: 350%; }
    .bg-wave {
      &__inner {
        @include md {
          min-width: 3000px; }
        @include sm {
          min-width: 4000px; }
        @include xxs {
          min-width: 3000px; } } } }
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include md {
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px dotted $red4; }
    @include sm {
      display: block;
      text-align: center; } }
  &__left {
    width: percentage(486/1200);
    @include lm {
      width: 34%; }
    @include md {
      width: 50%; }
    @include sm {
      width: 100%;
      margin-bottom: 50px; } }
  &__right {
    width: percentage(714/1200);
    @include lm {
      width: 63%; }
    @include md {
      width: 50%;
      padding-left: 50px; }
    @include sm {
      width: 100%;
      padding-left: 0; }
    &-inner {
      display: flex;
      align-items: flex-start;
      @include md {
        align-items: center; }
      @include sm {
        display: block; } } }
  &__rates {
    position: relative;
    margin-bottom: 33px;
    @include sm {
      margin-bottom: 0; }
    &-arrow {
      position: absolute;
      right: 0;
      top: 22%;
      transform: translateY(-50%);
      @include md {
        top: 50%;
        height: 100%; }
      @include sm {
        position: static;
        margin-bottom: 25px; }
      img {
        @include md {
          height: 100%; }
        @include sm {
          height: auto;
          width: 100%; } } } }
  &__head {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 25px; }
  &__presenation {
    position: relative;
    top: -5px;
    padding-left: 5%;
    @include lm {
      max-width: 220px;
      padding-left: 3%; }
    @include md {
      top: -28px; }
    @include sm {
      max-width: 155px;
      margin-left: auto;
      margin-right: auto;
      top: 0;
      text-align: left;
      padding-left: 0; } }
  &__desktop-btn {
    @include md {
      display: none; } }
  &__tablet {
    display: none;
    text-align: center;
    @include md {
      display: block; } }
  &--delivery {
    margin-bottom: 292px;
    @include md {
      margin-bottom: 255px; }
    @include sm {
      margin-bottom: 240px; }
    @include xxs {
      margin-bottom: 190px; }
    #{$this}__left {
      width: percentage(600/1200);
      @include sm {
        width: 100%; } }
    #{$this}__right {
      width: percentage(600/1200);
      @include sm {
        width: 100%; }
      &-inner {
        @include md {
          display: block; } } }
    #{$this}__rates {
      min-width: percentage(404/600);
      @include md {
        margin-bottom: 25px; }
      &-arrow {
        @include md {
          position: static; }
        img {
          @include md {
            height: auto;
            max-height: 15px;
            width: 100%; } } } }
    #{$this}__bg-wave {
      top: 55%; }
    #{$this}__presenation {
      @include md {
        position: static;
        padding-left: 0;
        max-width: 170px;
        margin-left: auto;
        margin-right: auto; } }
    .rates-items {
      display: inline-block;
      @include md {
        margin-bottom: 30px; } }
    .press-btn {
      @include md {
        display: flex; } } } }
.press-btn {
  $this: &;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  @include md {
    display: block; }
  @include sm {
    display: inline-flex; }
  i {
    display: block;
    position: relative;
    top: 3px;
    font-size: 0; }
  svg {
    width: 38px;
    height: 38px;
    fill: $red4;
    margin-right: 11px; }
  &__inner {
    line-height: 1.1;
    span {
      border-bottom: 1px dashed $red4;
      font-size: 15px;
      @include mreg;
      color: $red4;
      transition: border-color 0.3s; } }
  @include hover {
    #{$this}__inner {
      span {
        border-color: transparent; } } } }
.rates-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 29px;
  margin-right: 50px;
  border-bottom: 1px dotted $red4;
  @include lm {
    margin-right: 30px; }
  @include md {
    display: block;
    padding-bottom: 0;
    border-bottom: none; }
  @include sm {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-bottom: 43px; }
  @include xxs {
    flex-wrap: wrap;
    margin-bottom: 10px; }
  &__item {
    @include xxs {
      margin-bottom: 25px;
      padding: 0 10px; }
    &:not(:last-child) {
      margin-right: 50px;
      @include lm {
        margin-right: 22px; }
      @include md {
        margin-bottom: 25px; }
      @include sm {
        margin-bottom: 0; }
      @include xxs {
        margin-bottom: 25px;
        margin-right: 0; } } } }
.rates-item {
  display: flex;
  align-items: center;
  &__main-ico {
    margin-right: 10px;
    display: block;
    @include md {
      width: 50px;
      text-align: right; }
    svg {
      fill: $red3;
      font-size: 36px;
      @include md {
        font-size: 34px; } }
    &--big {
      position: relative;
      top: 2px;
      svg {
        font-size: 40px; } } }
  &__inner {
    @include md {
      text-align: left; }
    p > span {
      font-size: 22px;
      color: $red3; } }
  &__value {
    font-size: 35px;
    color: $red3;
    margin-bottom: 0px;
    line-height: 1;
    span {
      font-size: 20px; }
    svg {
      font-size: 12px;
      fill: $red3; } }
  &__text {
    font-size: 15px;
    color: $gray2;
    @include mreg; } }
