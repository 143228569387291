.carry {
  margin-bottom: 290px;
  @include md {
    margin-bottom: 215px; }
  &__inner {}
  &__title {
    @include md {
      margin-bottom: 80px; } }
  &__text {
    font-size: 14px;
    margin-bottom: 26px;
    line-height: 1.5; }
  &__descr {
    font-size: 14px;
    color: $gray3; } }
