.media {
  margin-bottom: 400px;
  @include lm {
    margin-bottom: 300px; }
  @include md {
    margin-bottom: 250px; }
  @include sm {
    margin-bottom: 200px; }
  @include xxs {
    margin-bottom: 160px; }
  &__title {}
  &__list-wrapper {
    @include md {
      overflow-x: auto; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @include md {
      width: 800px;
      flex-wrap: nowrap; }
    &-item {
      margin: 0 20px 20px;
      flex-shrink: 1;
      img {
        max-height: 50px;
        max-width: 100%;
        @include lm {
          max-height: 36px; } } } } }
