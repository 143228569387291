.footer {
  $this: &;
  position: relative;
  @include md {
    padding-bottom: 80px;
    padding-top: 80px; }
  @include xxs {
    padding-bottom: 40px; }
  &__bg {
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    z-index: -1;
    @include md {
      height: 180%; }
    @include xxs {
      height: 190%; }
    &-inner {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      min-width: 1920px;
      width: 100%;
      @include md {
        min-width: 1px;
        width: auto;
        height: 100%; }
      img {
        width: 100%;
        height: auto;
        max-height: 1112px;
        @include md {
          width: auto;
          max-height: none;
          height: 100%; } } } }
  &__inner {
    @include md {
      display: flex;
      flex-wrap: wrap; }
    @include xxs {
      display: block; } }
  &__top {
    padding-bottom: 40px;
    border-bottom: 1px dotted rgba(white,0.3);
    margin-bottom: 102px;
    @include md {
      width: 50%;
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
      border-right: 1px dotted rgba(white,0.3);
      padding-right: 25px; }
    @include xxs {
      width: 100%;
      margin-bottom: 25px;
      padding: 0;
      border: none; } }
  &__bottom {
    padding-bottom: 77px;
    @include md {
      width: 50%;
      padding-bottom: 0;
      padding-left: 25px; }
    @include xxs {
      width: 100%;
      padding: 0; } }
  &__row {
    display: flex;
    align-items: flex-start;
    @include md {
      display: block; }
    .fblock {
      width: 512px;
      &:last-of-type {
        @include md {
          margin-bottom: 0; } }
      @include md {
        width: 100%; }
      &:nth-of-type(2n) {
        width: 424px;
        @include md {
          width: 100%; } }
      &:nth-of-type(3n) {
        width: 264px;
        @include md {
          width: 100%; } } } } }
.fblock {
  display: flex;
  align-items: flex-start;
  padding-right: 10px;
  margin-bottom: 50px;
  color: $white;
  @include md {
    min-height: 136px; }
  @include sm {
    min-height: 120px; }
  @include xxs {
    min-height: 1px;
    margin-bottom: 25px; }
  &:first-of-type {
    @include sm {
      min-height: 150px; }
    @include xxs {
      min-height: 1px; } }
  &__icon {
    position: relative;
    top: 1px;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    img {
      max-width: 30px;
      height: auto;
      max-height: 30px; } }
  &__spec-img {
    position: relative;
    top: -3px; }
  &__title {
    @include iblack;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 8px;
    &--mb-big {
      margin-bottom: 15px; } }
  &__text {
    font-size: 16px;
    @include mreg;
    @include md {
      padding-right: 10px; }
    @include xxs {
      padding-right: 0; }
    &--max-270 {
      max-width: 270px;
      @include md {
        max-width: 100%; } }
    a {
      color: inherit;
      border-bottom: 1px dashed $white;
      transition: opacity 0.3s;
      @include hover {
        opacity: 0.5; } }
    p {
      &:not(:last-of-type) {
        margin-bottom: 10px; } } }
  &__icons {
    display: flex;
    align-items: center;
    font-size: 0;
    height: 67px;
    @include sm {
      height: auto; }
    &-item {
      &:not(:last-of-type) {
        margin-right: 30px;
        @include sm {
          margin-right: 15px; } }
      &--img {
        img {
          max-height: 67px;
          @include md {
            max-height: 48px; }
          @include sm {
            max-height: 30px; } } }
      &:not(.fblock__icons-item--img) {
        img {
          height: 38px;
          @include sm {
            height: 28px; } } }
      i {
        display: block; } }
    a {
      transition: opacity 0.3s;
      @include hover {
        opacity: 0.07; } } } }
.icon-vk {
  fill: #436eab;
  font-size: 28px;
  @include sm {
    font-size: 22px; } }
.icon-facebook, .icon-twitter, .icon-viber, .icon-chat {
  font-size: 32px;
  @include sm {
    font-size: 26px; } }
.icon-facebook {
  fill: #385c8e; }
.icon-twitter {
  fill: #03a9f4; }
.icon-viber {
  fill: #a554e4;
  font-size: 41px;
  @include sm {
    font-size: 30px; } }
.icon-chat {
  fill: #7baf74;
  font-size: 44px;
  @include sm {
    font-size: 30px; } }
