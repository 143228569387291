.dropdown {
  &.is-active {
    > ul {
      display: block; } }
  &.is-disabled {
    opacity: .2; }
  & > input {
    position: relative;
    z-index: 21; }
  & > ul {
    position: absolute;
    display: none;
    left: 0;
    right: 0;
    top: calc(100% - 6px);
    z-index: 25;
    padding: 6px 0 0px;
    max-height: 300px;
    overflow-y: auto;
    text-align: left;
    background: #fff;
    list-style-type: none;
    border-radius: 0 0  7px  7px;
    box-shadow: 0 20px 49px rgba(0, 0, 0, 0.2);

    li {
      padding: 18px 40px 18px 30px;
      color: $gray;
      cursor: pointer;
      line-height: 1;
      font-size: 16px;
      transition: color .3s, background-color .3s;
      @include xxs {
        padding: 15px 10px 15px 15px; }
      &.is-active {
        color: #383737; }
      @include hover {
        background: #f6f6f6; }
      &.disabled-item {
        text-decoration: line-through;
        &:hover {
          color: gray; } }
      &.placeholder-item {
        display: none; } } }

  &.is-active {
    & > input {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      &:before {
        transform: rotateZ(180deg); } }
    ul {
      opacity: 1;
      visibility: visible; } } }
