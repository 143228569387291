.tell-us {
  margin-bottom: 296px;
  padding-bottom: 50px;
  @include md {
    margin-bottom: 220px;
    padding-bottom: 0; }
  @include sm {
    margin-bottom: 180px; }
  &__waves {}
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include lm {
      max-width: 990px;
      margin-left: auto;
      margin-right: auto; }
    @include md {
      position: relative;
      display: block;
      padding-top: 150px;
      max-width: 700px;
      min-height: 528px;
      text-align: center; }
    @include sm {
      min-height: 530px;
      max-width: 520px; }
    @include xxs {
      min-height: 0; } }

  &__text-wrap {
    position: relative;
    width: 550px;
    @include lm {
      width: 420px;
      flex-shrink: 0; }
    @include md {
      width: 100%;
      margin-bottom: 46px; }
    @include sm {
      margin-bottom: 25px; } }
  &__form-wrap {
    position: relative;
    width: 650px;
    padding-top: 115px;
    padding-left: 50px;
    padding-right: 50px;
    @include lm {
      width: auto;
      padding-right: 0;
      padding-left: 0; }
    @include md {
      position: static;
      width: 100%;
      padding-top: 0; } }
  &__big-text {
    @include lm {
      margin-bottom: 45px; }
    @include md {
      margin-bottom: 25px; }
    @include sm {
      margin-bottom: 20px; }
    span {
      small {
        @include md {
          display: none; } } }
    & > small {
      display: none;
      @include md {
        display: block;
        margin-top: 10px;
        max-width: 100%; } } }
  &__megaphone {
    position: absolute;
    left: 100%;
    top: -50px;
    @include lm {
      left: calc(100% + 55px); }
    @include md {
      top: -150px;
      left: 50%;
      margin-left: -63px; }
    svg {
      width: 109px;
      height: 120px; } }
  &__line {
    @include xxs {
      display: none; }
    &--top {
      top: 28px;
      left: 76px;
      width: 76px;
      @include lm {
        left: 122px; }
      @include md {
        width: 266px;
        top: 50px;
        left: 0;
        height: auto; }
      @include sm {
        width: 177px; }
      &-simple {
        top: 28px;
        width: 66px;
        left: -70px;
        @include lm {
          left: -30px; }
        @include md {
          width: 266px;
          top: 50px;
          left: auto;
          right: 0;
          height: auto; }
        @include sm {
          width: 177px; } } }
    &--bottom {
      top: calc(100% + 15px);
      left: -181px;
      width: 615px;
      @include lm {
        top: calc(100% + 2px); }
      @include md {
        display: none; } } }
  &__tooltip {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% + 12px);
    text-align: center;
    user-select: none;
    transition: opacity 0.4s;
    opacity: 0;
    @include is-touch {
      display: none; }
    img {
      width: 100%; }
    span {
      display: block;
      @include mreg;
      color: $white;
      font-size: 18px; } }
  .form {
    position: relative;
    &__field {
      position: relative;
      @include hover {
        .tell-us__tooltip {
          opacity: 1; } }
      @include md {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 42px; }
      @include sm {
        margin-bottom: 28px; }
      @include xxs {
        width: 100%; } }
    &__line {
      @include md {
        display: block; } } }
  .btn {
    min-width: 230px; } }
