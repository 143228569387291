.icon-arrow-down {
  width: 1.6em;
  height: 1em;
  fill: initial;
}

.icon-bike {
  width: 1.33em;
  height: 1em;
  fill: initial;
}

.icon-box {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-calendar {
  width: 0.9em;
  height: 1em;
  fill: initial;
}

.icon-call {
  width: 1.08em;
  height: 1em;
  fill: initial;
}

.icon-car {
  width: 1.47em;
  height: 1em;
  fill: initial;
}

.icon-car2 {
  width: 1.98em;
  height: 1em;
  fill: initial;
}

.icon-chair {
  width: 0.9em;
  height: 1em;
  fill: initial;
}

.icon-chat {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-circle-check {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-client-back {
  width: 1.02em;
  height: 1em;
  fill: initial;
}

.icon-clock {
  width: 0.68em;
  height: 1em;
  fill: initial;
}

.icon-clock2 {
  width: 1.21em;
  height: 1em;
  fill: initial;
}

.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-coins {
  width: 1.39em;
  height: 1em;
  fill: initial;
}

.icon-connect-hands {
  width: 1.59em;
  height: 1em;
  fill: initial;
}

.icon-egor-head {
  width: 0.86em;
  height: 1em;
  fill: initial;
}

.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}

.icon-gaz {
  width: 1.46em;
  height: 1em;
  fill: initial;
}

.icon-ico_fast {
  width: 1.07em;
  height: 1em;
  fill: initial;
}

.icon-ico_fiz {
  width: 0.82em;
  height: 1em;
  fill: initial;
}

.icon-ico_jur {
  width: 0.82em;
  height: 1em;
  fill: initial;
}

.icon-ico_route {
  width: 0.97em;
  height: 1em;
  fill: initial;
}

.icon-insurance {
  width: 0.83em;
  height: 1em;
  fill: initial;
}

.icon-inventarization {
  width: 1.04em;
  height: 1em;
  fill: initial;
}

.icon-label {
  width: 0.82em;
  height: 1em;
  fill: initial;
}

.icon-location {
  width: 0.71em;
  height: 1em;
  fill: initial;
}

.icon-location2 {
  width: 1.01em;
  height: 1em;
  fill: initial;
}

.icon-mind {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-money {
  width: 1.43em;
  height: 1em;
  fill: initial;
}

.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-presentation {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-products {
  width: 0.81em;
  height: 1em;
  fill: initial;
}

.icon-rocket {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-route {
  width: 1.01em;
  height: 1em;
  fill: initial;
}

.icon-rubble {
  width: 0.81em;
  height: 1em;
  fill: initial;
}

.icon-safe {
  width: 1.02em;
  height: 1em;
  fill: initial;
}

.icon-smile {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-speedometer {
  width: 0.94em;
  height: 1em;
  fill: initial;
}

.icon-status {
  width: 1.06em;
  height: 1em;
  fill: initial;
}

.icon-twitter {
  width: 1.2em;
  height: 1em;
  fill: initial;
}

.icon-user {
  width: 0.93em;
  height: 1em;
  fill: initial;
}

.icon-viber {
  width: 0.96em;
  height: 1em;
  fill: initial;
}

.icon-vk {
  width: 1.58em;
  height: 1em;
  fill: initial;
}

.icon-walk {
  width: 1.44em;
  height: 1em;
  fill: initial;
}

.icon-arrow-down {
  width: 1.6em;
  height: 1em;
  fill: initial;
}

.icon-bike {
  width: 1.33em;
  height: 1em;
  fill: initial;
}

.icon-box {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-calendar {
  width: 0.9em;
  height: 1em;
  fill: initial;
}

.icon-call {
  width: 1.08em;
  height: 1em;
  fill: initial;
}

.icon-car {
  width: 1.47em;
  height: 1em;
  fill: initial;
}

.icon-car2 {
  width: 1.98em;
  height: 1em;
  fill: initial;
}

.icon-chair {
  width: 0.9em;
  height: 1em;
  fill: initial;
}

.icon-chat {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-circle-check {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-client-back {
  width: 1.02em;
  height: 1em;
  fill: initial;
}

.icon-clock {
  width: 0.68em;
  height: 1em;
  fill: initial;
}

.icon-clock2 {
  width: 1.21em;
  height: 1em;
  fill: initial;
}

.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-coins {
  width: 1.39em;
  height: 1em;
  fill: initial;
}

.icon-connect-hands {
  width: 1.59em;
  height: 1em;
  fill: initial;
}

.icon-egor-head {
  width: 0.86em;
  height: 1em;
  fill: initial;
}

.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}

.icon-gaz {
  width: 1.46em;
  height: 1em;
  fill: initial;
}

.icon-ico_fast {
  width: 1.07em;
  height: 1em;
  fill: initial;
}

.icon-ico_fiz {
  width: 0.82em;
  height: 1em;
  fill: initial;
}

.icon-ico_jur {
  width: 0.82em;
  height: 1em;
  fill: initial;
}

.icon-ico_route {
  width: 0.97em;
  height: 1em;
  fill: initial;
}

.icon-insurance {
  width: 0.83em;
  height: 1em;
  fill: initial;
}

.icon-inventarization {
  width: 1.04em;
  height: 1em;
  fill: initial;
}

.icon-label {
  width: 0.82em;
  height: 1em;
  fill: initial;
}

.icon-location {
  width: 0.71em;
  height: 1em;
  fill: initial;
}

.icon-location2 {
  width: 1.01em;
  height: 1em;
  fill: initial;
}

.icon-mind {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-money {
  width: 1.43em;
  height: 1em;
  fill: initial;
}

.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-presentation {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-products {
  width: 0.81em;
  height: 1em;
  fill: initial;
}

.icon-rocket {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-route {
  width: 1.01em;
  height: 1em;
  fill: initial;
}

.icon-rubble {
  width: 0.81em;
  height: 1em;
  fill: initial;
}

.icon-safe {
  width: 1.02em;
  height: 1em;
  fill: initial;
}

.icon-smile {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-speedometer {
  width: 0.94em;
  height: 1em;
  fill: initial;
}

.icon-status {
  width: 1.06em;
  height: 1em;
  fill: initial;
}

.icon-twitter {
  width: 1.2em;
  height: 1em;
  fill: initial;
}

.icon-user {
  width: 0.93em;
  height: 1em;
  fill: initial;
}

.icon-viber {
  width: 0.96em;
  height: 1em;
  fill: initial;
}

.icon-vk {
  width: 1.58em;
  height: 1em;
  fill: initial;
}

.icon-walk {
  width: 1.44em;
  height: 1em;
  fill: initial;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

@font-face {
  font-family: Intro-Light;
  src: url("../fonts/Intro-Light.woff2") format("woff2"), url("../fonts/Intro-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Intro-Regular;
  src: url("../fonts/Intro-Regular.woff2") format("woff2"), url("../fonts/Intro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Intro-Bold;
  src: url("../fonts/Intro-Bold.woff2") format("woff2"), url("../fonts/Intro-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Intro-Black;
  src: url("../fonts/Intro-Black.woff2") format("woff2"), url("../fonts/Intro-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: MuseoCyrl-300;
  src: url("../fonts/MuseoCyrl-300.woff2") format("woff2"), url("../fonts/MuseoCyrl-300.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: MuseoCyrl-500;
  src: url("../fonts/MuseoCyrl-500.woff2") format("woff2"), url("../fonts/MuseoCyrl-500.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: MuseoCyrl-700;
  src: url("../fonts/MuseoCyrl-700.woff2") format("woff2"), url("../fonts/MuseoCyrl-700.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.l {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

@media only screen and (max-width: 449px) {
  .l {
    padding: 0 12px;
  }
}

.l--sm {
  max-width: 1170px;
}

.l--xs {
  max-width: 1140px;
}

.cols {
  display: flex;
  flex-wrap: wrap;
}

.col--50 {
  width: 50%;
}

.col--left {
  padding-right: 30px;
}

@media only screen and (max-width: 539px) {
  .col--left {
    padding: 0;
  }
}

.col--right {
  padding-left: 30px;
}

@media only screen and (max-width: 539px) {
  .col--right {
    padding: 0;
  }
}

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  height: 100%;
  z-index: 10;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #e8e8e8;
  background: #fff;
  position: relative;
  height: 100%;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 10px 50px 10px 30px;
  box-shadow: 0 2px 4px transparent;
  transition: box-shadow 0.3s;
  z-index: 10;
}

@media only screen and (max-width: 539px) {
  .selectric {
    padding: 10px 30px 10px 15px;
  }
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #959495;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 14px;
  height: 8px;
  right: 32px;
  top: 50%;
  margin-top: -2px;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .selectric .button {
    right: 20px;
  }
}

.selectric .button:after {
  content: '';
  display: block;
  width: 14px;
  height: 8px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  background-image: url("../img/select-arrow.svg");
  transition: transform 0.3s;
  transform-origin: 50% 50%;
}

.selectric-open .button:after {
  transform: rotateZ(180deg);
}

.selectric-open .selectric {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: calc(100% - 6px);
  padding-top: 6px;
  left: 0;
  background: #fff;
  z-index: 9;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items {
  border-radius: 0 0 7px 7px;
  overflow: hidden;
  box-shadow: 0 20px 49px rgba(0, 0, 0, 0.2);
}

.selectric-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  display: block;
  padding: 18px 40px 18px 30px;
  color: #959495;
  cursor: pointer;
  transform: background-color 0.3s;
}

@media only screen and (max-width: 539px) {
  .selectric-items li {
    padding: 15px 10px 15px 15px;
  }
}

.selectric-items li.selected {
  color: #383737;
}

.no-touch .selectric-items li:hover {
  background: #f6f6f6;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}

body {
  background: #fff;
  color: #262626;
  font-size: 18px;
  line-height: 1.33;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: MuseoCyrl-300, Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 539px) {
  body {
    font-size: 16px;
  }
}

body.is-modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

h1, h2, h3, h4, h5, h6, strong {
  font-style: normal;
}

a {
  text-decoration: none;
}

.out {
  overflow: hidden;
}

.center {
  text-align: center;
}

.link {
  color: #ea242e;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
}

.article {
  font-size: 16px;
  line-height: 1.4;
}

@media only screen and (max-width: 539px) {
  .article {
    font-size: 14px;
  }
}

.article p:not(:last-of-type) {
  margin-bottom: 22px;
}

.arrow-anim {
  position: absolute;
}

.is-hidden {
  display: none;
}

.icon-location {
  transition: fill 0.3s;
}

.no-touch .icon-location:hover {
  fill: #b42028;
}

.js-lazy {
  transition: opacity 0.4s;
  opacity: 1;
}

.js-lazy[data-src] {
  opacity: 0;
}

.js-anim-elem {
  opacity: 0;
}

@media only screen and (max-width: 1023px) {
  .js-anim-elem {
    opacity: 1;
  }
}

.js-anim-elem[data-animation="arrow"] {
  opacity: 0;
  font-size: 0;
  overflow: hidden;
}

.js-anim-elem[data-animation="arrow"] img {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 1023px) {
  .js-anim-elem[data-animation="arrow"] img {
    height: 100%;
  }
}

.js-logo-scene-item, .js-who-title, .js-manager-icon-smile {
  opacity: 0;
}

.is-touch body .js-tablet-anim-elem {
  opacity: 0;
}

@media only screen and (min-width: 1024px) {
  .is-touch body .js-tablet-anim-elem {
    opacity: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .is-touch body .js-tablet-anim-elem .js-anim-elem {
    opacity: 1;
  }
}

.js-anim-elem, .js-tablet-anim-elem {
  opacity: 0;
}

.section.is-animated .js-anim-elem, .section.is-animated .js-tablet-anim-elem {
  opacity: 1;
}

.is-phone .section .js-tablet-anim-elem {
  opacity: 1;
}

.is-phone .js-who-title, .is-phone .js-anim-elem, .is-phone .js-tablet-anim-elem, .is-phone .js-logo-scene-item, .is-phone .js-hero-content-elem, .is-phone .js-who-title, .is-phone .js-manager-icon-smile {
  opacity: 1;
}

.is-phone .js-manager-icon-sad {
  opacity: 0;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: MuseoCyrl-300, Arial, Helvetica, sans-serif;
  color: #fff;
  background-color: #ea242e;
  border-radius: 43px;
  border: none;
  appearance: none;
  padding: 28px 45px;
}

@media only screen and (max-width: 539px) {
  .btn {
    padding: 20px 30px;
  }
}

.btn i {
  position: relative;
  top: 2px;
  display: block;
  margin-right: 10px;
}

.btn svg {
  font-size: 24px;
  fill: #fff;
}

.btn--anim {
  background-image: url("../img/btn-bg-waves.png");
  background-repeat: repeat;
  background-position: 90% 90%;
  transition: background-position 1s linear;
}

.no-touch .btn--anim:hover {
  transition: background-position 6s linear;
  background-position: 15% 0%;
}

.btn--shadow-l {
  box-shadow: 0 0 63.2px 4.8px #cca781;
}

@media only screen and (max-width: 1023px) {
  .btn--shadow-l {
    box-shadow: 0 0 63.2px 4.8px rgba(204, 167, 129, 0.7);
  }
}

.btn--light-shadow {
  box-shadow: 0 0 63px 5px rgba(92, 5, 11, 0.19);
}

.btn--yellow {
  background-color: #ffb900;
  border-radius: 32px;
  padding: 21px 32px;
  font-size: 18px;
  color: #6c3649;
}

.btn--red-dark {
  background-color: transparent;
  border-radius: 32px;
  border: 1px solid #664358;
  padding: 17px 32px;
  font-size: 18px;
  color: #664358;
  transition: color 0.3s, border-color 0.3s;
}

.no-touch .btn--red-dark:hover {
  color: #ea242e;
  border-color: #ea242e;
}

.connect-btn {
  display: flex;
  padding: 19px;
  align-items: center;
  border-radius: 32px;
  border: 1px solid #502e5e;
  transition: border-color 0.3s, color 0.3s;
}

@media only screen and (max-width: 1023px) {
  .connect-btn {
    display: inline-flex;
  }
}

.connect-btn i {
  margin-right: 7px;
  font-size: 0;
  line-height: 1;
}

.connect-btn svg {
  font-size: 17px;
  fill: #502e5e;
  transition: fill 0.3s;
}

.no-touch .connect-btn:hover {
  border-color: #ea242e;
  color: #ea242e;
}

.no-touch .connect-btn:hover svg {
  fill: #ea242e;
}

.user-btn {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #502e5e;
  font-size: 0;
  transition: color 0.3s;
}

.user-btn i {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.user-btn svg {
  font-size: 15px;
  fill: #502e5e;
  transition: fill 0.3s;
}

.no-touch .user-btn:hover {
  border-color: #ea242e;
}

.no-touch .user-btn:hover svg {
  fill: #ea242e;
}

.title {
  font-style: normal;
  text-transform: uppercase;
  font-family: Intro-Black, Arial, Helvetica, sans-serif;
}

.title--xl, .title--xl-modal {
  color: #b01a24;
  font-size: 72px;
  font-family: Intro-Light, Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.title--xl strong, .title--xl-modal strong {
  font-weight: normal;
  font-family: Intro-Black, Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 1239px) {
  .title--xl {
    font-size: 66px;
  }
}

@media only screen and (max-width: 1023px) {
  .title--xl {
    font-size: 60px;
    line-height: 1;
  }
}

@media only screen and (max-width: 767px) {
  .title--xl {
    font-size: 48px;
  }
}

@media only screen and (max-width: 539px) {
  .title--xl {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .title--xl-modal {
    font-size: 60px;
  }
}

@media only screen and (max-width: 599px) {
  .title--xl-modal {
    font-size: 50px;
  }
}

@media only screen and (max-width: 539px) {
  .title--xl-modal {
    font-size: 30px;
  }
}

.title--l {
  font-size: 67px;
  line-height: 1;
}

@media only screen and (max-width: 1499px) {
  .title--l {
    font-size: 57px;
  }
}

@media only screen and (max-width: 1239px) {
  .title--l {
    font-size: 44px;
  }
}

@media only screen and (max-width: 1023px) {
  .title--l {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .title--l {
    font-size: 30px;
  }
}

@media only screen and (max-width: 539px) {
  .title--l {
    font-size: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .title--l {
    font-size: 20px;
  }
}

.title--m {
  font-size: 34px;
  font-family: Intro-Bold, Arial, Helvetica, sans-serif;
  letter-spacing: 1.1px;
}

@media only screen and (max-width: 767px) {
  .title--m {
    font-size: 28px;
  }
}

.title--white {
  color: #fff;
}

.title--red-dark {
  color: #5a354b;
}

.title--grad {
  background: linear-gradient(to right, #b01a24, #e9202e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 1023px) {
  .title--grad {
    line-height: 1.33;
  }
}

.title--right {
  text-align: right;
}

.title--center {
  text-align: center;
}

.title--mb-m {
  margin-bottom: 22px;
}

.title--mb-lg {
  margin-bottom: 61px;
}

@media only screen and (max-width: 767px) {
  .title--mb-lg {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 539px) {
  .title--mb-lg {
    margin-bottom: 40px;
  }
}

.sub-title {
  color: #5a354b;
  font-size: 24px;
  line-height: 1;
  text-transform: uppercase;
  font-family: Intro-Regular, Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 539px) {
  .sub-title {
    font-size: 18px;
  }
}

.sub-title strong {
  font-family: Intro-Black, Arial, Helvetica, sans-serif;
}

.sub-title--center {
  text-align: center;
}

.sub-title--mb-s {
  margin-bottom: 28px;
}

.sub-title--mb-m {
  margin-bottom: 36px;
}

.sub-title--mb-lg {
  margin-bottom: 60px;
}

.sub-title--lowercase {
  text-transform: none;
}

.text {
  line-height: 1.2;
  color: #fff;
}

.text--spec {
  text-transform: uppercase;
  font-family: Intro-Light, Arial, Helvetica, sans-serif;
}

.text--spec strong {
  font-weight: normal;
  font-family: Intro-Black, Arial, Helvetica, sans-serif;
}

.text--spec span {
  display: block;
}

@media only screen and (max-width: 539px) {
  .text--spec span {
    display: inline;
  }
}

.text--spec-22 {
  font-size: 22px;
}

@media only screen and (max-width: 1239px) {
  .text--spec-22 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 539px) {
  .text--spec-22 {
    font-size: 18px;
  }
}

.text--spec-36 {
  font-size: 36px;
  line-height: 1;
}

@media only screen and (max-width: 1239px) {
  .text--spec-36 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .text--spec-36 {
    font-size: 33px;
  }
}

@media only screen and (max-width: 539px) {
  .text--spec-36 {
    font-size: 28px;
  }
}

.text--spec-62 {
  font-size: 62px;
  font-family: Intro-Black, Arial, Helvetica, sans-serif;
  line-height: 0.9;
}

@media only screen and (max-width: 1239px) {
  .text--spec-62 {
    font-size: 58px;
    line-height: 1.33;
  }
}

@media only screen and (max-width: 1023px) {
  .text--spec-62 {
    font-size: 65px;
    line-height: 1;
  }
}

.text--spec-65 {
  font-size: 65px;
  font-family: Intro-Black, Arial, Helvetica, sans-serif;
  line-height: 0.9;
}

@media only screen and (max-width: 1239px) {
  .text--spec-65 {
    font-size: 52px;
  }
}

@media only screen and (max-width: 767px) {
  .text--spec-65 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 539px) {
  .text--spec-65 {
    font-size: 29px;
  }
}

.text--spec-65 span {
  display: inline-block;
}

.text--spec-65 small {
  display: inline-block;
  vertical-align: top;
  max-width: 310px;
  font-size: 26px;
  line-height: 1.1;
  font-family: Intro-Light, Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 1239px) {
  .text--spec-65 small {
    max-width: 260px;
    font-size: 21px;
  }
}

@media only screen and (max-width: 767px) {
  .text--spec-65 small {
    font-size: 26px;
  }
}

@media only screen and (max-width: 539px) {
  .text--spec-65 small {
    font-size: 20px;
  }
}

.text--spec-72 {
  line-height: 0.82;
  font-family: Intro-Black, Arial, Helvetica, sans-serif;
}

.text--spec-72-light {
  line-height: 1;
  font-family: Intro-Light, Arial, Helvetica, sans-serif;
}

.text--spec-72-light strong {
  font-family: Intro-Black, Arial, Helvetica, sans-serif;
}

.text--spec-72, .text--spec-72-light {
  font-size: 72px;
}

@media only screen and (max-width: 1239px) {
  .text--spec-72, .text--spec-72-light {
    font-size: 60px;
  }
}

@media only screen and (max-width: 1023px) {
  .text--spec-72, .text--spec-72-light {
    font-size: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .text--spec-72, .text--spec-72-light {
    font-size: 60px;
  }
}

@media only screen and (max-width: 539px) {
  .text--spec-72, .text--spec-72-light {
    font-size: 40px;
  }
}

.text--right {
  text-align: right;
}

.text--mb-10 {
  margin-bottom: 10px;
}

.text--mb-16 {
  margin-bottom: 16px;
}

.text--mb-28 {
  margin-bottom: 28px;
}

.form__line {
  display: flex;
  align-items: flex-start;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .form__line {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.form__line--vertical {
  align-items: center;
}

.form__line--center {
  justify-content: center;
}

.form__line--mb-30 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1023px) {
  .form__line--tablet-spec {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .form__line--tablet-spec {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .form__line--tablet-spec .form__field {
    width: 100%;
    margin-right: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .form__line--tablet-spec:first-of-type .form__field {
    width: calc(50% - 5px);
  }
}

@media only screen and (max-width: 767px) {
  .form__line--tablet-spec:first-of-type .form__field {
    width: 100%;
  }
}

.form__field {
  position: relative;
  height: 79px;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1499px) {
  .form__field {
    height: 64px;
  }
}

@media only screen and (max-width: 539px) {
  .form__field {
    margin-right: 0;
    height: 50px;
  }
}

.form__field--mr-0 {
  margin-right: 0;
}

.form__field--w-406 {
  width: 406px;
}

@media only screen and (max-width: 1023px) {
  .form__field--w-406 {
    width: calc(50% - 5px);
  }
}

@media only screen and (max-width: 539px) {
  .form__field--w-406 {
    width: 100%;
  }
}

.form__field--w-395 {
  width: 395px;
}

@media only screen and (max-width: 1023px) {
  .form__field--w-395 {
    width: calc(50% - 5px);
    margin-right: 0;
  }
}

@media only screen and (max-width: 539px) {
  .form__field--w-395 {
    width: 100%;
  }
}

.form__field--w-364 {
  width: 364px;
}

.form__field--6-66 {
  width: 66%;
}

.form__field--w-177 {
  width: 177px;
}

@media only screen and (max-width: 449px) {
  .form__field--w-177 {
    width: 46.57%;
  }
}

.form__field--w-190 {
  width: 190px;
}

@media only screen and (max-width: 449px) {
  .form__field--w-190 {
    width: 50%;
  }
}

.form__field--w-300 {
  width: 300px;
}

.form__field--w-736 {
  width: 736px;
}

.form__field--mr-big {
  margin-right: 22px;
}

.form__field--w-max {
  width: 100%;
}

.form__field--mb-20 {
  margin-bottom: 20px;
}

.form__field--icon .form__input {
  padding: 10px 60px 10px 30px;
}

@media only screen and (max-width: 539px) {
  .form__field--icon .form__input {
    padding: 10px 45px 10px 15px;
  }
}

.form__field--icon i {
  position: absolute;
  z-index: 22;
  top: 50%;
  right: 30px;
  transform: translate3d(0, 0, 0);
  transform: translateY(-50%);
  display: inline-block;
  cursor: pointer;
}

@media only screen and (max-width: 539px) {
  .form__field--icon i {
    right: 15px;
  }
}

.form__field--icon svg {
  font-size: 21px;
  fill: #f0291f;
}

.form__field--icon-disable i {
  pointer-events: none;
}

.form__field--sm-height {
  height: 64px;
}

.form__field.is-error .form__input {
  box-shadow: inset 0 0 13px rgba(234, 36, 46, 0.06);
  border-color: #ea242e;
}

.form__input {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 30px 10px 30px;
  border: 1px solid #fff;
  border-radius: 7px;
  background-color: #fff;
  font-size: 16px;
  transition: border-color 0.3s;
  font-family: MuseoCyrl-300, Arial, Helvetica, sans-serif;
  color: #959495;
}

.form__input::placeholder {
  color: #959495;
}

@media only screen and (max-width: 1499px) {
  .form__input {
    padding: 10px 30px 10px 30px;
  }
}

@media only screen and (max-width: 539px) {
  .form__input {
    padding: 10px 15px 10px 15px;
  }
}

.form__input--font-big {
  font-size: 26px;
}

.form__input--border-gray {
  border-color: #e8e8e8;
}

.form__checkbox {
  text-align: center;
  transition: opacity 0.3s;
}

.no-touch .form__checkbox:hover {
  opacity: 0.8;
}

.form__checkbox input[type="checkbox"] {
  display: none;
}

.form__checkbox input[type="checkbox"]:checked + label svg {
  opacity: 1;
}

.form__checkbox input[type="checkbox"]:checked + label i:before {
  opacity: 0;
}

.form__checkbox label {
  display: inline-block;
  text-align: center;
  font-size: 0;
  cursor: pointer;
  user-select: none;
}

.form__checkbox i {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 0;
  margin-right: 6px;
}

.form__checkbox i:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-transition: opacity 0.3s;
}

.form__checkbox span {
  border-bottom: 1px dashed #fff;
  font-size: 18px;
  color: #fff;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
}

.form__checkbox svg {
  width: 22px;
  height: 22px;
  fill: #f0291f;
  transition: opacity 0.3s;
  opacity: 0;
}

@media only screen and (max-width: 1023px) {
  .form__checkbox--dark i {
    top: -3px;
  }
}

.form__checkbox--dark i:before {
  border-color: rgba(38, 38, 38, 0.3);
}

.form__checkbox--dark span {
  color: #262626;
  border-bottom: none;
}

@media only screen and (max-width: 1023px) {
  .form__checkbox--dark span {
    font-size: 14px;
  }
}

.form__checkbox--dark svg {
  fill: #262626;
}

.form__link {
  color: #ea242e;
  border-bottom: 1px dashed #ea242e;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
  font-size: 18px;
}

@media only screen and (max-width: 1023px) {
  .form__link {
    font-size: 14px;
  }
}

.form__spec-group {
  position: relative;
  width: 380px;
}

@media only screen and (max-width: 1023px) {
  .form__spec-group {
    margin-left: 12px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 449px) {
  .form__spec-group {
    width: 100%;
    margin-left: 0;
  }
}

.form__spec-group .form__field {
  transition: opacity 0.3s;
}

@media only screen and (max-width: 539px) {
  .form__spec-group .form__field:first-of-type {
    margin-right: 10px;
  }
}

.form__spec-group .form__checkbox i {
  top: 2px;
}

.form__spec-group.is-checked .form__field {
  pointer-events: none;
  user-select: none;
  opacity: 0.8;
  cursor: default;
}

.form__spec-group-decor-line {
  position: relative;
  left: -6px;
  margin-bottom: 12px;
  width: calc(100% + 12px);
  user-select: none;
}

.form__spec-group .sssl > span {
  border-color: transparent;
}

.form__spec-group .sssl > span:before {
  opacity: 0;
}

.form__spec-block {
  height: auto;
}

.form__btn-wrap--mb-60 {
  margin-bottom: 60px;
}

@media only screen and (max-width: 1023px) {
  .form__btn-wrap--mb-60 {
    margin-bottom: 30px;
  }
}

.form-block {
  padding: 10px 10px 10px 10px;
  font-size: 16px;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
  color: #262626;
}

.form-block__head {
  margin-bottom: 10px;
}

.form-block__list {
  max-height: 150px;
  overflow-y: auto;
  list-style-type: none;
  counter-reset: list1;
}

.form-block__list > li:before {
  content: counter(list1) ". ";
  counter-increment: list1;
}

.form-block__list li {
  font-size: 16px;
}

.form-block__list li:not(:last-of-type) {
  margin-bottom: 10px;
}

.form-block__list a, .form-block__list button {
  display: inline;
  vertical-align: top;
  border: none;
  background: none;
  color: #ea242e;
  border-bottom: 1px dashed #f0291f;
  font-family: MuseoCyrl-300, Arial, Helvetica, sans-serif;
  text-align: left;
}

.form-block__list button {
  appearance: none;
  font-size: 16px;
}

.form-block__field {
  font-size: 14px;
}

.form-block__field span {
  padding-right: 3px;
}

.date-pick {
  position: relative;
  height: 100%;
  cursor: pointer;
}

.date-pick__input {
  position: relative;
  z-index: 21;
  display: block;
  pointer-events: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s;
}

.date-pick.is-active .date-pick__input {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ui-datepicker-inline {
  display: none;
  font-size: 16px;
  position: absolute;
  top: calc(100% - 6px);
  left: 0;
  z-index: 20;
  background: #fff;
  border-radius: 0 7px 7px 7px;
  padding: 27px 25px 7px;
  cursor: default;
}

.ui-datepicker-inline .ui-datepicker-header {
  position: relative;
}

.ui-datepicker-inline .ui-datepicker-prev, .ui-datepicker-inline .ui-datepicker-next {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 16px;
  height: 16px;
  font-size: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  cursor: pointer;
}

.ui-datepicker-inline .ui-datepicker-next {
  right: 0;
  background-image: url("../img/calendar-arrow-next.svg");
}

.ui-datepicker-inline .ui-datepicker-prev {
  left: 0;
  background-image: url("../img/calendar-arrow-prev.svg");
}

.ui-datepicker-inline .ui-datepicker-title {
  position: relative;
  text-align: center;
  position: relative;
  padding-bottom: 12px;
  color: #ea242e;
}

.ui-datepicker-inline .ui-datepicker-title:after {
  content: '';
  position: absolute;
  z-index: -1;
  left: -25px;
  top: 100%;
  transform: translateY(-5px);
  height: 33px;
  width: calc(100% + 50px);
  background-color: #f6f4f4;
}

.ui-datepicker-inline .ui-datepicker-month:after {
  content: ',';
}

.ui-datepicker-inline td a {
  color: #616161;
  display: inline-block;
  margin-bottom: 11px;
  padding: 2px;
}

.ui-datepicker-inline td.ui-datepicker-today a {
  color: #ea242e;
}

.ui-datepicker-inline td.ui-datepicker-current-day a {
  position: relative;
}

.ui-datepicker-inline td.ui-datepicker-current-day a:before {
  content: '';
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(235, 33, 46, 0.2);
}

.ui-datepicker-inline td.ui-datepicker-other-month {
  opacity: 0.5;
}

.ui-datepicker-inline tr td:not(:last-child), .ui-datepicker-inline tr th:not(:last-child) {
  padding-right: 15px;
}

.ui-datepicker-inline th {
  padding-bottom: 17px;
}

.waves {
  position: absolute;
  left: 0%;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
}

.waves__inner {
  position: absolute;
  left: 50%;
  top: 0;
  min-width: 1920px;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
}

.is-phone .waves__inner {
  min-width: 1024px;
}

.waves canvas {
  position: absolute;
  top: 0;
  left: -1%;
  width: 100%;
  height: 100%;
  width: 102%;
}

@media only screen and (min-width: 1800px) {
  .waves canvas {
    width: 105%;
    left: -2.5%;
  }
}

.number-plate {
  position: relative;
}

.number-plate__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

@media only screen and (max-width: 767px) {
  .number-plate__inner {
    display: block;
  }
}

.number-plate__block {
  width: 33.33333%;
  padding-right: 25px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 1023px) {
  .number-plate__block {
    width: 50%;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .number-plate__block {
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0;
  }
}

.number-plate__imgs {
  position: absolute;
  z-index: -2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

@media only screen and (max-width: 1023px) {
  .number-plate__imgs {
    top: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .number-plate__imgs {
    top: 45%;
  }
}

.number-plate__imgs img {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s;
  opacity: 0;
  max-width: 100vw;
  height: auto;
}

@media only screen and (max-width: 1239px) {
  .number-plate__imgs img {
    width: 1100px;
    max-width: none;
  }
}

.number-plate__imgs img.is-active {
  opacity: 1;
}

@media only screen and (max-width: 1023px) {
  .number-plate--how-work .number-plate__inner {
    justify-content: space-between;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .number-plate--how-work .number-plate__inner {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .number-plate--how-work .number-plate__block {
    width: 44%;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .number-plate--how-work .number-plate__block {
    width: 100%;
  }
}

.number-block {
  position: relative;
  display: flex;
  max-width: 300px;
  cursor: pointer;
}

@media only screen and (max-width: 1023px) {
  .number-block {
    max-width: 100%;
  }
}

.number-block__number {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 54px;
  min-width: 40px;
  color: #ea242e;
  font-size: 60px;
  padding-right: 16px;
  line-height: 0.8;
  font-family: Intro-Light, Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 767px) {
  .number-block__number {
    min-height: 36px;
    font-size: 45px;
  }
}

.number-block__number:before {
  content: '';
  position: absolute;
  left: -8px;
  right: -0px;
  bottom: -3px;
  top: -3px;
  background: #fff;
  z-index: 0;
  border-radius: 4px;
  transition: opacity 0.5s;
  opacity: 0;
}

@media only screen and (max-width: 1023px) {
  .number-block__number:before {
    top: -5px;
    bottom: -5px;
  }
}

.number-block__number span {
  position: relative;
  z-index: 1;
  top: 3px;
}

.is-mac .number-block__number span {
  top: 8px;
}

.is-touch .number-block__number span {
  top: 6px;
}

.number-block__text-wrap {
  position: relative;
  flex-grow: 1;
  min-height: 54px;
}

@media only screen and (max-width: 767px) {
  .number-block__text-wrap {
    min-height: 36px;
  }
}

.number-block__real-text {
  position: relative;
  visibility: hidden;
}

.number-block__view-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.number-block__view-text div {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 18px;
}

.number-block__view-text div:before {
  content: '';
  position: absolute;
  left: -10px;
  right: -6px;
  bottom: -3px;
  top: -3px;
  background: #fff;
  z-index: 0;
  border-radius: 0 4px 4px 0;
  opacity: 0;
  transition: opacity 0.5s;
}

@media only screen and (max-width: 1023px) {
  .number-block__view-text div:before {
    top: -5px;
    bottom: -5px;
  }
}

.number-block__view-text div:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 70%;
  box-shadow: 0 -10px 30px rgba(90, 53, 75, 0.1), 0 10px 30px rgba(90, 53, 75, 0.1), -70px -10px 30px rgba(90, 53, 75, 0.1), -70px 10px 30px rgba(90, 53, 75, 0.1);
  z-index: -1;
  transition: opacity 0.5s;
  opacity: 0;
}

.number-block__view-text div span {
  position: relative;
  z-index: 1;
}

.number-block--max-320 {
  max-width: 320px;
}

@media only screen and (max-width: 1023px) {
  .number-block--max-320 {
    max-width: 100%;
  }
}

.number-block.is-active .number-block__view-text div:before, .number-block.is-active .number-block__view-text div:after {
  opacity: 1;
}

.number-block.is-active .number-block__number:before {
  opacity: 1;
}

.number-block.is-hidden {
  opacity: 0;
}

.is-phone .number-block.is-hidden {
  opacity: 1;
}

.number-block-text-stile {
  font-size: 15px;
  line-height: 18px;
}

.list li {
  position: relative;
  padding-left: 17px;
  font-size: 15px;
  letter-spacing: 0.4px;
}

@media only screen and (max-width: 767px) {
  .list li {
    padding-left: 0;
  }
}

.list li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  bottom: 3px;
  width: 5px;
  border-radius: 3px;
  background-color: #b01a24;
}

@media only screen and (max-width: 767px) {
  .list li:before {
    display: none;
  }
}

.list li:not(:last-child) {
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .list li:not(:last-child) {
    margin-bottom: 12px;
  }
}

.list--max-460 {
  max-width: 460px;
}

@media only screen and (max-width: 767px) {
  .list--max-460 {
    max-width: 100%;
  }
}

.bg-wave {
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -1;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  font-size: 0;
  transform: translateY(-50%);
  transform-origin: 50% 50%;
}

.bg-wave__inner {
  position: absolute;
  top: 0%;
  left: 50%;
  width: 110%;
  height: 100%;
  min-width: 1920px;
  opacity: 0;
  transform: translateX(-50%);
}

.is-phone .bg-wave__inner {
  opacity: 1;
}

.bg-wave__inner img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.comet-wrap {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 1920px;
  height: 920px;
  pointer-events: none;
}

.comet-wrap__left {
  left: 0;
  bottom: 0;
}

.comet-wrap__right {
  right: 0;
  top: 0;
}

.comet-wrap__left, .comet-wrap__right {
  position: absolute;
  font-size: 0;
}

.comet-wrap__left img, .comet-wrap__left svg, .comet-wrap__right img, .comet-wrap__right svg {
  width: 785px;
  height: 547px;
  opacity: 0.5;
}

.comet-wrap--route-delivery, .comet-wrap--how-work {
  height: 560px;
  top: -86px;
}

.comet-wrap--route-delivery .comet-wrap__left, .comet-wrap--how-work .comet-wrap__left {
  bottom: -180px;
}

.comet-wrap--route-delivery .comet-wrap__right, .comet-wrap--how-work .comet-wrap__right {
  top: -180px;
}

.comet-wrap--small-view .comet-wrap__left {
  left: -400px;
}

.comet-wrap--small-view .comet-wrap__right {
  right: -400px;
}

.dropdown.is-active > ul {
  display: block;
}

.dropdown.is-disabled {
  opacity: .2;
}

.dropdown > input {
  position: relative;
  z-index: 21;
}

.dropdown > ul {
  position: absolute;
  display: none;
  left: 0;
  right: 0;
  top: calc(100% - 6px);
  z-index: 25;
  padding: 6px 0 0px;
  max-height: 300px;
  overflow-y: auto;
  text-align: left;
  background: #fff;
  list-style-type: none;
  border-radius: 0 0  7px  7px;
  box-shadow: 0 20px 49px rgba(0, 0, 0, 0.2);
}

.dropdown > ul li {
  padding: 18px 40px 18px 30px;
  color: #959495;
  cursor: pointer;
  line-height: 1;
  font-size: 16px;
  transition: color .3s, background-color .3s;
}

@media only screen and (max-width: 539px) {
  .dropdown > ul li {
    padding: 15px 10px 15px 15px;
  }
}

.dropdown > ul li.is-active {
  color: #383737;
}

.no-touch .dropdown > ul li:hover {
  background: #f6f6f6;
}

.dropdown > ul li.disabled-item {
  text-decoration: line-through;
}

.dropdown > ul li.disabled-item:hover {
  color: gray;
}

.dropdown > ul li.placeholder-item {
  display: none;
}

.dropdown.is-active > input {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown.is-active > input:before {
  transform: rotateZ(180deg);
}

.dropdown.is-active ul {
  opacity: 1;
  visibility: visible;
}

.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 4;
  padding: 48px 0 0;
  height: 109px;
}

@media only screen and (max-width: 539px) {
  .header {
    padding: 30px 0 0;
    height: 78px;
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  display: inline-block;
  font-size: 0;
}

.header__logo img {
  width: 148px;
  height: 47px;
}

.header__wrap {
  flex-grow: 1;
}

.header__wrap-inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
}

@media only screen and (max-width: 1023px) {
  .header__wrap-inner {
    position: absolute;
    z-index: 49;
    display: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    min-height: 650px;
    padding: 53px 20px 30px;
    background: linear-gradient(#fff, rgba(255, 255, 255, 0.8) 60%, rgba(255, 255, 255, 0));
  }
}

@media only screen and (max-width: 539px) {
  .header__wrap-inner {
    padding: 95px 20px 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .header__wrap-inner.is-active {
    display: block;
  }
}

.header__spec-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

@media only screen and (max-width: 1023px) {
  .header__spec-inner {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .header__spec-inner {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .header__spec-inner:first-of-type {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .header__spec-inner:first-of-type {
    padding-right: 50px;
  }
}

@media only screen and (max-width: 539px) {
  .header__spec-inner:first-of-type {
    justify-content: center;
    padding-right: 0;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 539px) {
  .header__spec-inner:nth-of-type(2) {
    flex-wrap: wrap;
  }
}

.header__menu {
  border-left: 2px dotted rgba(0, 0, 0, 0.4);
  padding-left: 21px;
  margin-left: 25px;
}

@media only screen and (max-width: 1023px) {
  .header__menu {
    line-height: 1.5;
    margin-left: 30px;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .header__menu {
    border: none;
    padding: 0;
  }
}

@media only screen and (max-width: 539px) {
  .header__menu {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
    text-align: center;
  }
}

.header__menu li {
  display: inline-block;
  vertical-align: top;
}

.header__menu li:not(:last-child) {
  margin-right: 25px;
}

.header__menu li a {
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
  font-size: 16px;
  transition: color 0.3s;
}

.no-touch .header__menu li a:hover {
  color: #ea242e;
}

.header__connect-btn {
  margin-left: 35px;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
  font-size: 16px;
}

@media only screen and (max-width: 1023px) {
  .header__connect-btn {
    margin-left: 2px;
  }
}

.header__phone {
  font-size: 30px;
  transition: color 0.3s;
  display: flex;
  align-items: flex-end;
  line-height: 1;
}

@media only screen and (max-width: 1023px) {
  .header__phone {
    position: relative;
    display: block;
    margin-right: 36px;
    padding-right: 36px;
    border-right: 2px dotted rgba(0, 0, 0, 0.4);
    line-height: 1;
  }
}

@media only screen and (max-width: 539px) {
  .header__phone {
    font-size: 18px;
    padding-right: 20px;
    margin-right: 20px;
  }
}

.header__phone span {
  position: relative;
  bottom: 3px;
  font-size: 20px;
  margin-right: 3px;
}

@media only screen and (max-width: 1023px) {
  .header__phone span {
    margin-right: 0;
    bottom: 1px;
  }
}

@media only screen and (max-width: 539px) {
  .header__phone span {
    bottom: 0;
    font-size: 14px;
  }
}

.no-touch .header__phone:hover {
  color: #ea242e;
}

.header__mob-btn {
  display: none;
  font-size: 16px;
  color: #5a354b;
  border-bottom: 1px dashed #5a354b;
}

@media only screen and (max-width: 1023px) {
  .header__mob-btn {
    display: inline-block;
  }
}

@media only screen and (max-width: 539px) {
  .header__mob-btn {
    font-size: 14px;
  }
}

.header .user-btn {
  margin-left: 16px;
}

@media only screen and (max-width: 1023px) {
  .header .user-btn {
    margin-left: 22px;
  }
}

.header a {
  color: #502e5e;
}

.header__burger {
  display: none;
  position: relative;
  z-index: 50;
  margin-left: auto;
}

@media only screen and (max-width: 1023px) {
  .header__burger {
    display: block;
    width: 48px;
    height: 38px;
  }
}

@media only screen and (max-width: 539px) {
  .header__burger {
    width: 44px;
    height: 34px;
  }
}

.header__burger:before, .header__burger:after, .header__burger span {
  position: absolute;
  left: 0;
  height: 6px;
  width: 100%;
  border-radius: 3px;
  background: #ea242e;
  transition: transform 0.3s, opacity 0.3s, width 0.3s;
}

@media only screen and (max-width: 539px) {
  .header__burger:before, .header__burger:after, .header__burger span {
    height: 4px;
  }
}

.header__burger:before {
  content: '';
  top: 0;
  width: 38px;
  transform-origin: 0 0;
}

.header__burger:after {
  content: '';
  left: auto;
  right: 0;
  bottom: 0;
  width: 38px;
  transform-origin: 0% 100%;
}

.header__burger span {
  top: 50%;
  transform: translateY(-50%);
}

.header__burger.is-active span {
  opacity: 0;
}

.header__burger.is-active:before {
  transform: rotate(44deg);
  width: 48px;
}

@media only screen and (max-width: 539px) {
  .header__burger.is-active:before {
    width: 44px;
  }
}

.header__burger.is-active:after {
  transform: rotate(-44deg);
  width: 48px;
}

@media only screen and (max-width: 539px) {
  .header__burger.is-active:after {
    width: 44px;
  }
}

@media only screen and (max-width: 1023px) {
  .header .js-hero-content-elem {
    opacity: 1;
  }
}

.section {
  position: relative;
  z-index: 2;
}

.section__wrap {
  position: relative;
  z-index: 2;
}

.hero__wrap {
  position: relative;
  z-index: 1;
  height: 950px;
}

@media only screen and (max-width: 1499px) {
  .hero__wrap {
    height: 740px;
  }
}

.hero__bg-wrap {
  position: relative;
  width: 100%;
  height: 1150px;
  overflow: hidden;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(to top, #ba6cb8, #f6c9b9);
  transform: translate3d(0, 0, 0);
  margin-bottom: -1150px;
  pointer-events: none;
}

@media only screen and (max-width: 1239px) {
  .hero__bg-wrap {
    min-width: 1500px;
    left: 50%;
    margin-left: -750px;
    height: 900px;
    margin-bottom: -900px;
  }
}

@media only screen and (max-width: 539px) {
  .hero__bg-wrap {
    height: 750px;
    margin-bottom: -750px;
  }
}

.hero__bg {
  width: 100%;
  height: 1150px;
}

@media only screen and (max-width: 1239px) {
  .hero__bg {
    height: 1250px;
  }
}

@media only screen and (max-width: 1023px) {
  .hero__bg {
    height: 1150px;
  }
}

@media only screen and (max-width: 930px) and (max-height: 930px) {
  .hero__bg {
    opacity: 0;
  }
}

.hero__clouds {
  position: absolute;
  left: 50%;
  top: -4%;
  transform: translateX(-50%);
  width: 104%;
  height: auto;
  z-index: 0;
}

@media only screen and (max-width: 1239px) {
  .hero__clouds {
    width: 104vw;
    min-width: 768px;
  }
}

.hero__plan {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
}

.hero__plan--1 {
  max-width: 100%;
  width: auto;
  z-index: 3;
  bottom: -10%;
}

@media only screen and (max-width: 1499px) {
  .hero__plan--1 {
    bottom: 13%;
  }
}

@media only screen and (min-width: 3000px) {
  .hero__plan--1 {
    bottom: -16%;
  }
}

.hero__plan--2 {
  z-index: 2;
  width: 102%;
  bottom: -6%;
}

@media only screen and (max-width: 1499px) {
  .hero__plan--2 {
    bottom: 20%;
    width: 116%;
  }
}

@media only screen and (min-width: 1920px) {
  .hero__plan--2 {
    bottom: -4%;
  }
}

@media only screen and (min-width: 3000px) {
  .hero__plan--2 {
    bottom: -44%;
  }
}

.hero__plan--3 {
  z-index: 1;
  width: 102%;
  bottom: -4%;
}

@media only screen and (max-width: 1499px) {
  .hero__plan--3 {
    bottom: 22%;
    width: 116%;
  }
}

@media only screen and (min-width: 1920px) {
  .hero__plan--3 {
    bottom: -1%;
  }
}

@media only screen and (min-width: 3000px) {
  .hero__plan--3 {
    bottom: -34%;
  }
}

.hero__content {
  position: relative;
  z-index: 2;
  margin-top: 109px;
}

@media only screen and (max-width: 1023px) {
  .hero__content {
    margin-top: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__content {
    margin-top: 100px;
  }
}

.hero__inner {
  position: relative;
  z-index: 2;
  padding: 53px 0 0;
}

@media only screen and (max-width: 1499px) {
  .hero__inner {
    padding: 38px 0 0;
  }
}

@media only screen and (max-width: 539px) {
  .hero__inner {
    padding: 10px 0 0;
  }
}

.hero__cols {
  margin-bottom: 38px;
}

@media only screen and (max-width: 539px) {
  .hero__cols {
    margin-bottom: 20px;
  }
}

.hero__man {
  position: absolute;
  left: -75px;
  top: -65px;
  z-index: 1;
  pointer-events: none;
}

@media only screen and (max-width: 1499px) {
  .hero__man {
    left: -15px;
    top: -50px;
  }
}

@media only screen and (max-width: 1239px) {
  .hero__man {
    left: auto;
    right: 55%;
    top: 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .hero__man {
    top: -10px;
    right: 52%;
  }
}

@media only screen and (max-width: 767px) {
  .hero__man {
    top: 10px;
    right: 44%;
  }
}

@media only screen and (max-width: 539px) {
  .hero__man {
    top: -10px;
    right: 39%;
  }
}

@media only screen and (max-width: 449px) {
  .hero__man {
    right: 30%;
  }
}

.hero__man svg {
  width: 639px;
  height: 999px;
}

@media only screen and (max-width: 1499px) {
  .hero__man svg {
    width: 561px;
    height: 887px;
  }
}

@media only screen and (max-width: 1239px) {
  .hero__man svg {
    width: 450px;
    height: 729px;
  }
}

@media only screen and (max-width: 1023px) {
  .hero__man svg {
    width: 500px;
    height: 750px;
  }
}

@media only screen and (max-width: 539px) {
  .hero__man svg {
    width: 450px;
    height: 729px;
  }
}

.hero__col {
  margin-left: 50%;
}

@media only screen and (max-width: 1239px) {
  .hero__col {
    width: 510px;
    margin-left: 48%;
  }
}

@media only screen and (max-width: 1023px) {
  .hero__col {
    width: 47%;
    margin-left: 53%;
  }
}

@media only screen and (max-width: 767px) {
  .hero__col {
    width: 50%;
    margin-left: 50%;
  }
}

@media only screen and (max-width: 449px) {
  .hero__col {
    margin-left: 45%;
    width: 55%;
  }
}

.hero__title {
  margin-bottom: 10px;
}

.hero__title span {
  display: none;
  font-size: 20px;
  font-weight: normal;
  font-family: Intro-Regular, Arial, Helvetica, sans-serif;
  line-height: 1.2;
}

@media only screen and (max-width: 1499px) {
  .hero__title span {
    display: inline-block;
    max-width: 268px;
  }
}

@media only screen and (max-width: 1239px) {
  .hero__title span {
    max-width: 230px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1023px) {
  .hero__title span {
    max-width: 100%;
    text-transform: none;
    font-size: 22px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .hero__title span {
    font-size: 20px;
  }
}

@media only screen and (max-width: 539px) {
  .hero__title span {
    font-size: 18px;
  }
}

@media only screen and (max-width: 449px) {
  .hero__title span {
    font-size: 15px;
  }
}

.hero__text {
  max-width: 320px;
  font-size: 24px;
  font-family: Intro-Regular, Arial, Helvetica, sans-serif;
  color: #fff;
}

@media only screen and (max-width: 1499px) {
  .hero__text {
    display: none;
  }
}

.hero__form {
  margin-bottom: 80px;
}

@media only screen and (max-width: 1499px) {
  .hero__form {
    margin-bottom: 36px;
  }
}

.hero__mouse-btn {
  display: inline-block;
  vertical-align: top;
  appearance: none;
  border: none;
  background: none;
}

@media only screen and (max-width: 1023px) {
  .hero__mouse-btn {
    display: none;
  }
}

.hero__mouse-btn svg {
  fill: #fff;
  width: 34px;
  height: 60px;
}

.hero__mob-image {
  display: none;
}

.is-phone .hero__mob-image {
  position: relative;
  display: block;
  left: 50%;
  transform: translateX(-50%);
}

.hero .waves {
  height: 800px;
  transform: translateY(59%);
}

@media only screen and (max-width: 1499px) {
  .hero .waves {
    transform: translateY(67%);
  }
}

@media only screen and (max-width: 1023px) {
  .hero .waves {
    transform: translateY(76%);
  }
}

@media only screen and (max-width: 539px) {
  .hero .waves {
    transform: translateY(64%);
  }
}

.is-phone .hero .waves canvas {
  display: none;
}

.hero .mouse-arrow {
  transform-origin: 50% 50%;
  animation: mouse-arrow 2s infinite;
}

.hero .selectric .button {
  display: none;
}

@keyframes mouse-arrow {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  25% {
    opacity: 1;
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 0;
  }
}

.js-hero-content-elem {
  opacity: 0;
}

@media only screen and (max-width: 930px) and (max-height: 930px) {
  .js-hero-content-elem {
    opacity: 1;
  }
}

.js-hero-bg.is-visible, .js-hero-content-elem.is-visible {
  opacity: 1;
}

.js-custom-parallax {
  transform: translate3d(0, 0, 0);
}

.who {
  position: relative;
  z-index: 3;
  padding-top: 60px;
  margin-bottom: 328px;
  pointer-events: none;
}

@media only screen and (max-width: 1279px) {
  .who {
    margin-bottom: 220px;
  }
}

@media only screen and (max-width: 1023px) {
  .who {
    padding-top: 120px;
  }
}

@media only screen and (max-width: 539px) {
  .who {
    padding-top: 80px;
  }
}

.who__title {
  margin-bottom: 85px;
}

@media only screen and (max-width: 1023px) {
  .who__title {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .who__title {
    margin-bottom: 60px;
  }
}

.who__cols {
  display: flex;
  margin-bottom: 72px;
}

@media only screen and (max-width: 1023px) {
  .who__cols {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 539px) {
  .who__cols {
    display: block;
  }
}

.who__cols--justify {
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0;
}

@media only screen and (max-width: 1023px) {
  .who__cols--justify {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 539px) {
  .who__cols--justify {
    text-align: center;
  }
}

.who__col {
  flex-grow: 0;
}

@media only screen and (max-width: 1239px) {
  .who__col br {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .who__col {
    text-align: center;
  }
}

.who__col--left {
  width: 55.5%;
  text-align: right;
  flex-grow: 1;
  margin-right: 100px;
}

@media only screen and (max-width: 1239px) {
  .who__col--left {
    margin-right: 60px;
  }
}

@media only screen and (max-width: 1023px) {
  .who__col--left {
    width: 100%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 20px;
  }
}

.who__col--right {
  width: 35.83333%;
}

@media only screen and (max-width: 1023px) {
  .who__col--right {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .who__col--left, .who__col--right {
    width: 100%;
  }
}

.who__col--sm {
  width: 230px;
  flex-grow: 0;
}

@media only screen and (max-width: 1023px) {
  .who__col--sm {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .who__col--sm {
    width: 240px;
  }
}

@media only screen and (max-width: 539px) {
  .who__col--sm {
    width: 260px;
    display: inline-block;
    vertical-align: top;
  }
}

@media only screen and (max-width: 1023px) {
  .who-item {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .who-item {
    margin-bottom: 50px;
  }
}

.who-item__img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 38px;
  height: 210px;
}

@media only screen and (max-width: 1023px) {
  .who-item__img {
    height: 190px;
    margin-bottom: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .who-item__img {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}

.who-item__img svg {
  max-width: 100%;
  max-height: 100%;
}

.who-item__text {
  border-top: 1px dotted #62384b;
  padding-top: 20px;
  text-align: center;
  font-size: 16px;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
}

.anim-scene-item, .auto {
  position: absolute;
}

.anim-scene-item1, .auto1 {
  top: 3%;
  left: 0px;
  width: 160px;
  height: 120px;
}

.anim-scene-item2, .auto2 {
  top: 13%;
  right: 2%;
  width: 50%;
  height: 51%;
}

.anim-scene-item3, .auto3 {
  bottom: 7%;
  left: 5%;
  width: 43%;
  height: 43%;
}

.anim-scene-item4, .auto4 {
  bottom: 9%;
  right: 0;
  width: 40%;
  height: 40%;
}

.anim-scene-item.bigcar, .auto.bigcar {
  top: 10%;
  left: 4%;
  width: 80%;
  height: 80%;
}

.anim-scene-item.bigcar-box, .auto.bigcar-box {
  top: 24%;
  left: 39%;
  width: 40%;
  height: 40%;
}

.anim-scene-item.loader, .auto.loader {
  left: 57%;
  top: 51%;
  width: 30%;
  height: 30%;
}

.anim-scene-item.loader-box, .auto.loader-box {
  top: 51%;
  left: 71%;
  width: 15%;
  height: 15%;
}

.anim-scene-item.static-loader, .auto.static-loader {
  right: 3%;
  top: 42%;
  width: 21%;
  height: 21%;
}

.anim-scene-item.static-loader-box, .auto.static-loader-box {
  top: 36%;
  right: 10%;
  width: 10%;
  height: 16%;
}

.anim-scene-item.scanner, .auto.scanner {
  top: 27%;
  right: 4%;
  width: 34%;
  height: 57%;
}

.anim-scene-item.box1, .anim-scene-item.box2, .anim-scene-item.box3, .anim-scene-item.box4, .auto.box1, .auto.box2, .auto.box3, .auto.box4 {
  top: 50%;
  left: 50%;
}

.anim-scene-item.box1, .auto.box1 {
  width: 35%;
  height: 40%;
}

.is-phone .anim-scene-item.box1, .is-phone .auto.box1 {
  top: 38%;
  left: 28%;
}

.anim-scene-item.box2, .auto.box2 {
  width: 25%;
  height: 24%;
}

.is-phone .anim-scene-item.box2, .is-phone .auto.box2 {
  top: 20%;
  left: 32%;
}

.anim-scene-item.box3, .auto.box3 {
  width: 10%;
  height: 11%;
}

.is-phone .anim-scene-item.box3, .is-phone .auto.box3 {
  top: 16%;
  right: 38%;
}

.anim-scene-item.box4, .auto.box4 {
  height: 12%;
  width: 16%;
}

.is-phone .anim-scene-item.box4, .is-phone .auto.box4 {
  top: 63%;
  left: 5%;
}

.anim-scene-item.courier1, .auto.courier1 {
  top: 18%;
  left: 10%;
  height: 60%;
  width: 43%;
}

.anim-scene-item.courier2, .auto.courier2 {
  top: 18%;
  height: 50%;
  left: 37%;
  width: 37%;
}

.anim-scene-item.courier3, .auto.courier3 {
  top: 37%;
  left: 47%;
  height: 45%;
  width: 43%;
}

.know {
  margin-bottom: 350px;
}

@media only screen and (max-width: 1023px) {
  .know {
    margin-bottom: 200px;
  }
}

.know__inner {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .know__inner {
    display: block;
  }
}

.know__left {
  width: 275px;
  flex-shrink: 0;
}

@media only screen and (max-width: 1023px) {
  .know__left {
    width: 100%;
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 88px;
    display: flex;
  }
}

@media only screen and (max-width: 539px) {
  .know__left {
    display: block;
  }
}

.know__left-text {
  text-align: right;
}

@media only screen and (max-width: 539px) {
  .know__left-text {
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) {
  .know__left-text:nth-of-type(2) {
    margin-left: 26px;
    font-size: 30px;
    text-align: left;
  }
}

@media only screen and (max-width: 539px) {
  .know__left-text:nth-of-type(2) {
    font-size: 18px;
    margin-left: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .know__left .text--spec-22 {
    line-height: 1.1;
  }
}

@media only screen and (max-width: 539px) {
  .know__left .text--spec-22 strong {
    display: block;
  }
}

.know__right {
  width: 925px;
  padding-left: 100px;
  flex-shrink: 1;
}

@media only screen and (max-width: 1279px) {
  .know__right {
    padding-left: 70px;
  }
}

@media only screen and (max-width: 1023px) {
  .know__right {
    padding-left: 0;
    width: 100%;
    min-height: 322px;
  }
}

@media only screen and (max-width: 767px) {
  .know__right {
    min-height: 313px;
  }
}

@media only screen and (max-width: 539px) {
  .know__right {
    min-height: 1px;
  }
}

.know-block {
  position: relative;
  max-width: 76.36364%;
  padding-top: 44px;
  padding-bottom: 62px;
  padding-left: 16.50794%;
}

@media only screen and (max-width: 1279px) {
  .know-block {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 1023px) {
  .know-block {
    padding: 80px 5px 0;
    margin: 0 auto;
    width: 100%;
    max-width: 658px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .know-block {
    max-width: 500px;
  }
}

.know-block__woman {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1023px) {
  .know-block__woman {
    left: 50%;
    top: 0;
  }
}

.know-block__woman svg {
  width: 88px;
  height: 123px;
}

@media only screen and (max-width: 1023px) {
  .know-block__content {
    margin-bottom: 53px;
  }
}

@media only screen and (max-width: 767px) {
  .know-block__content {
    margin-bottom: 57px;
  }
}

@media only screen and (max-width: 539px) {
  .know-block__content {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .know-block__content .text span {
    display: inline;
  }
}

@media only screen and (max-width: 767px) {
  .know-block__content .text--spec-72 {
    font-size: 57px;
  }
}

@media only screen and (max-width: 539px) {
  .know-block__content .text--spec-72 {
    font-size: 33px;
  }
}

.know-block__btn {
  position: absolute;
  min-width: 286px;
  left: 100%;
  top: 50%;
  transform: translate(-74px, -50%);
}

@media only screen and (max-width: 1279px) {
  .know-block__btn {
    left: auto;
    right: 0;
    transform: translate(150px, -50%);
  }
}

@media only screen and (max-width: 1023px) {
  .know-block__btn {
    position: static;
    transform: none;
  }
}

.know-block__line {
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .know-block__line {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .know-block__line {
    width: 173px;
  }
}

@media only screen and (max-width: 539px) {
  .know-block__line {
    display: none;
  }
}

.know-block__line--top {
  top: 0;
}

@media only screen and (max-width: 1023px) {
  .know-block__line--top {
    top: -20px;
  }
}

.know-block__line--bottom {
  bottom: 0;
}

@media only screen and (max-width: 1023px) {
  .know-block__line--bottom {
    left: auto;
    right: 0;
    bottom: auto;
    top: -20px;
  }
}

.how-work {
  position: relative;
  margin-bottom: 332px;
}

@media only screen and (max-width: 1023px) {
  .how-work {
    margin-bottom: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .how-work {
    margin-bottom: 180px;
  }
}

.how-work__sub-text {
  margin-bottom: 54px;
  text-align: center;
}

.how-work__title, .how-work__sub-title, .how-work__sub-text {
  position: relative;
  z-index: 1;
}

.how-work__tabs {
  margin-bottom: 66px;
}

@media only screen and (max-width: 767px) {
  .how-work__tabs {
    margin-bottom: 58px;
  }
}

.how-work__btn {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  margin: 0 32px 55px;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .how-work__btn {
    line-height: 1.2;
  }
}

@media only screen and (max-width: 539px) {
  .how-work__btn {
    margin: 0;
  }
}

.how-work__btn img {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 110%;
  height: auto;
  transform: translate(-50%, -20%);
}

.how-work__btn i {
  position: absolute;
  top: 210%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: block;
  font-size: 0;
}

.how-work__btn svg {
  width: 10px;
  height: 6px;
  fill: #ea242e;
}

@media only screen and (max-width: 539px) {
  .how-work__btn img, .how-work__btn i {
    display: none;
  }
}

.tabs__nav {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 116px;
}

@media only screen and (max-width: 1023px) {
  .tabs__nav {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 539px) {
  .tabs__nav {
    display: block;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.tabs__nav-separator {
  position: relative;
  margin: 0 8px;
  width: 90px;
  font-size: 0;
  height: 2px;
}

@media only screen and (max-width: 767px) {
  .tabs__nav-separator {
    width: 20px;
  }
}

@media only screen and (max-width: 539px) {
  .tabs__nav-separator {
    display: none;
  }
}

.tabs__nav-separator-first-img, .tabs__nav-separator-second-img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  transition: opacity 0.3s;
  transform-origin: 50% 50%;
}

@media only screen and (max-width: 767px) {
  .tabs__nav-separator-first-img, .tabs__nav-separator-second-img {
    display: none;
  }
}

.tabs__nav-separator-first-img {
  opacity: 0;
  transform: translate(-50%, -50%);
}

.tabs__nav-separator-second-img {
  opacity: 1;
  transform: translate(-50%, -50%);
}

.tabs__nav-item {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 15px 27px;
  border-radius: 30px;
  background: #fff;
}

@media only screen and (max-width: 539px) {
  .tabs__nav-item:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .tabs__nav-item {
    padding: 17px 22px;
  }
}

.tabs__nav-item:before, .tabs__nav-item:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s opacity;
  border-radius: 30px;
}

.tabs__nav-item:before {
  border: 1px solid #5a354b;
}

.tabs__nav-item:after {
  border: 1px solid #ea242e;
  opacity: 0;
}

.tabs__nav-item-arrow {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0%);
  z-index: 0;
  width: 100%;
  height: 5px;
  transition: 0.3s opacity;
  opacity: 0;
  overflow: hidden;
}

.tabs__nav-item-arrow:before {
  content: '';
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background: #ea242e;
}

.tabs__nav-item-arrow:after {
  content: '';
  position: absolute;
  z-index: 1;
  left: 50%;
  top: -1px;
  width: 8px;
  height: 8px;
  border: 1px solid #ea242e;
  background: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
}

.tabs__nav-item span {
  position: relative;
  display: block;
  color: #5a354b;
  font-size: 18px;
  border-bottom: 1px dashed #5a354b;
  transition: 0.3s border-color, 0.3s color;
}

.tabs__nav-item i {
  display: block;
  font-size: 0;
  margin-right: 8px;
}

@media only screen and (max-width: 767px) {
  .tabs__nav-item i {
    display: none;
  }
}

.tabs__nav-item svg {
  width: 23px;
  height: 28px;
  fill: #5a354b;
  transition: 0.3s fill;
}

.tabs__nav-item img {
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translate(-50%, 100%);
  width: 102%;
  height: auto;
  transition: 0.3s opacity;
  opacity: 0;
  font-size: 0;
}

.tabs__nav-item.is-active:before {
  opacity: 0;
}

.tabs__nav-item.is-active:after {
  opacity: 1;
}

.tabs__nav-item.is-active img, .tabs__nav-item.is-active .tabs__nav-item-arrow {
  opacity: 1;
}

.tabs__nav-item.is-active span {
  border-color: transparent;
  color: #ea242e;
}

.tabs__nav-item.is-active svg {
  fill: #ea242e;
}

.tabs__nav-item.is-active + .tabs__nav-separator .tabs__nav-separator-first-img {
  opacity: 1;
}

.tabs__nav-item.is-active + .tabs__nav-separator .tabs__nav-separator-second-img {
  opacity: 0;
}

.tabs__content {
  position: relative;
  z-index: 0;
}

.tabs__item:not(.is-active) {
  display: none;
}

.tabs__nav-helper {
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0px;
  transform: translateY(100%);
  height: 5px;
  width: 100%;
  overflow: hidden;
}

.tabs__nav-helper:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 10px;
  width: 100%;
  border: 1px solid #bfbfbf;
  border-radius: 7px;
}

.tabs--better .tabs__nav {
  margin-bottom: 103px;
}

@media only screen and (max-width: 1023px) {
  .tabs--better .tabs__nav {
    margin-bottom: 55px;
  }
}

.tabs--carry {
  width: 100%;
}

.tabs--carry .tabs__mob-scroll-wrap {
  overflow-x: auto;
}

.tabs--carry .tabs__nav {
  margin-bottom: 44px;
}

@media only screen and (max-width: 1023px) {
  .tabs--carry .tabs__nav {
    margin-bottom: 35px;
    width: 1024px;
  }
}

@media only screen and (max-width: 767px) {
  .tabs--carry .tabs__nav {
    width: 860px;
  }
}

@media only screen and (max-width: 539px) {
  .tabs--carry .tabs__nav {
    display: flex;
    max-width: none;
  }
}

.tabs--carry .tabs__nav-item {
  z-index: 1;
  justify-content: center;
  border: none;
  padding: 0;
  height: 124px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1023px) {
  .tabs--carry .tabs__nav-item {
    height: 80px;
  }
}

@media only screen and (max-width: 539px) {
  .tabs--carry .tabs__nav-item {
    margin-bottom: 0;
  }
}

.tabs--carry .tabs__nav-item:before, .tabs--carry .tabs__nav-item:after {
  display: none;
}

.tabs--carry .tabs__nav-item i {
  display: block;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 10px;
  height: 6px;
  margin: 0;
  overflow: hidden;
  transition: opacity 0.4;
  opacity: 0;
}

.tabs--carry .tabs__nav-item i:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  display: block;
  width: 6px;
  height: 6px;
  border: 1px solid #bfbfbf;
  border-radius: 0;
  background: #fff;
}

.tabs--carry .tabs__nav-item:not(:last-of-type) {
  margin-right: 60px;
}

@media only screen and (max-width: 1023px) {
  .tabs--carry .tabs__nav-item:not(:last-of-type) {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 539px) {
  .tabs--carry .tabs__nav-item:not(:last-of-type) {
    margin-right: 20px;
  }
}

.tabs--carry .tabs__nav-item img {
  position: static;
  max-width: 100%;
  height: auto;
  max-height: 90%;
  transform: none;
  opacity: 1;
}

@media only screen and (max-width: 1023px) {
  .tabs--carry .tabs__nav-item img {
    max-height: 70px;
    max-width: none;
  }
}

.tabs--carry .tabs__nav-item.is-active i {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .tabs--carry .tabs__nav-item.is-mob-hidden {
    display: none;
  }
}

.descr-block {
  position: relative;
  z-index: 1;
  margin-bottom: 365px;
}

@media only screen and (max-width: 1023px) {
  .descr-block {
    margin-bottom: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .descr-block {
    margin-bottom: 200px;
  }
}

.descr-block__l {
  position: relative;
  z-index: 1;
}

.descr-block__bg-wave {
  height: 1330px;
}

@media only screen and (max-width: 1023px) {
  .descr-block__bg-wave {
    height: 350%;
  }
}

@media only screen and (max-width: 1023px) {
  .descr-block__bg-wave .bg-wave__inner {
    min-width: 3000px;
  }
}

@media only screen and (max-width: 767px) {
  .descr-block__bg-wave .bg-wave__inner {
    min-width: 4000px;
  }
}

@media only screen and (max-width: 539px) {
  .descr-block__bg-wave .bg-wave__inner {
    min-width: 3000px;
  }
}

.descr-block__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 1023px) {
  .descr-block__inner {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px dotted #ea242e;
  }
}

@media only screen and (max-width: 767px) {
  .descr-block__inner {
    display: block;
    text-align: center;
  }
}

.descr-block__left {
  width: 40.5%;
}

@media only screen and (max-width: 1239px) {
  .descr-block__left {
    width: 34%;
  }
}

@media only screen and (max-width: 1023px) {
  .descr-block__left {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .descr-block__left {
    width: 100%;
    margin-bottom: 50px;
  }
}

.descr-block__right {
  width: 59.5%;
}

@media only screen and (max-width: 1239px) {
  .descr-block__right {
    width: 63%;
  }
}

@media only screen and (max-width: 1023px) {
  .descr-block__right {
    width: 50%;
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .descr-block__right {
    width: 100%;
    padding-left: 0;
  }
}

.descr-block__right-inner {
  display: flex;
  align-items: flex-start;
}

@media only screen and (max-width: 1023px) {
  .descr-block__right-inner {
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .descr-block__right-inner {
    display: block;
  }
}

.descr-block__rates {
  position: relative;
  margin-bottom: 33px;
}

@media only screen and (max-width: 767px) {
  .descr-block__rates {
    margin-bottom: 0;
  }
}

.descr-block__rates-arrow {
  position: absolute;
  right: 0;
  top: 22%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 1023px) {
  .descr-block__rates-arrow {
    top: 50%;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .descr-block__rates-arrow {
    position: static;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .descr-block__rates-arrow img {
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .descr-block__rates-arrow img {
    height: auto;
    width: 100%;
  }
}

.descr-block__head {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 25px;
}

.descr-block__presenation {
  position: relative;
  top: -5px;
  padding-left: 5%;
}

@media only screen and (max-width: 1239px) {
  .descr-block__presenation {
    max-width: 220px;
    padding-left: 3%;
  }
}

@media only screen and (max-width: 1023px) {
  .descr-block__presenation {
    top: -28px;
  }
}

@media only screen and (max-width: 767px) {
  .descr-block__presenation {
    max-width: 155px;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    text-align: left;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .descr-block__desktop-btn {
    display: none;
  }
}

.descr-block__tablet {
  display: none;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .descr-block__tablet {
    display: block;
  }
}

.descr-block--delivery {
  margin-bottom: 292px;
}

@media only screen and (max-width: 1023px) {
  .descr-block--delivery {
    margin-bottom: 255px;
  }
}

@media only screen and (max-width: 767px) {
  .descr-block--delivery {
    margin-bottom: 240px;
  }
}

@media only screen and (max-width: 539px) {
  .descr-block--delivery {
    margin-bottom: 190px;
  }
}

.descr-block--delivery .descr-block__left {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .descr-block--delivery .descr-block__left {
    width: 100%;
  }
}

.descr-block--delivery .descr-block__right {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .descr-block--delivery .descr-block__right {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .descr-block--delivery .descr-block__right-inner {
    display: block;
  }
}

.descr-block--delivery .descr-block__rates {
  min-width: 67.33333%;
}

@media only screen and (max-width: 1023px) {
  .descr-block--delivery .descr-block__rates {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .descr-block--delivery .descr-block__rates-arrow {
    position: static;
  }
}

@media only screen and (max-width: 1023px) {
  .descr-block--delivery .descr-block__rates-arrow img {
    height: auto;
    max-height: 15px;
    width: 100%;
  }
}

.descr-block--delivery .descr-block__bg-wave {
  top: 55%;
}

@media only screen and (max-width: 1023px) {
  .descr-block--delivery .descr-block__presenation {
    position: static;
    padding-left: 0;
    max-width: 170px;
    margin-left: auto;
    margin-right: auto;
  }
}

.descr-block--delivery .rates-items {
  display: inline-block;
}

@media only screen and (max-width: 1023px) {
  .descr-block--delivery .rates-items {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .descr-block--delivery .press-btn {
    display: flex;
  }
}

.press-btn {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

@media only screen and (max-width: 1023px) {
  .press-btn {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .press-btn {
    display: inline-flex;
  }
}

.press-btn i {
  display: block;
  position: relative;
  top: 3px;
  font-size: 0;
}

.press-btn svg {
  width: 38px;
  height: 38px;
  fill: #ea242e;
  margin-right: 11px;
}

.press-btn__inner {
  line-height: 1.1;
}

.press-btn__inner span {
  border-bottom: 1px dashed #ea242e;
  font-size: 15px;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
  color: #ea242e;
  transition: border-color 0.3s;
}

.no-touch .press-btn:hover .press-btn__inner span {
  border-color: transparent;
}

.rates-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 29px;
  margin-right: 50px;
  border-bottom: 1px dotted #ea242e;
}

@media only screen and (max-width: 1239px) {
  .rates-items {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .rates-items {
    display: block;
    padding-bottom: 0;
    border-bottom: none;
  }
}

@media only screen and (max-width: 767px) {
  .rates-items {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-bottom: 43px;
  }
}

@media only screen and (max-width: 539px) {
  .rates-items {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 539px) {
  .rates-items__item {
    margin-bottom: 25px;
    padding: 0 10px;
  }
}

.rates-items__item:not(:last-child) {
  margin-right: 50px;
}

@media only screen and (max-width: 1239px) {
  .rates-items__item:not(:last-child) {
    margin-right: 22px;
  }
}

@media only screen and (max-width: 1023px) {
  .rates-items__item:not(:last-child) {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .rates-items__item:not(:last-child) {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 539px) {
  .rates-items__item:not(:last-child) {
    margin-bottom: 25px;
    margin-right: 0;
  }
}

.rates-item {
  display: flex;
  align-items: center;
}

.rates-item__main-ico {
  margin-right: 10px;
  display: block;
}

@media only screen and (max-width: 1023px) {
  .rates-item__main-ico {
    width: 50px;
    text-align: right;
  }
}

.rates-item__main-ico svg {
  fill: #5a354b;
  font-size: 36px;
}

@media only screen and (max-width: 1023px) {
  .rates-item__main-ico svg {
    font-size: 34px;
  }
}

.rates-item__main-ico--big {
  position: relative;
  top: 2px;
}

.rates-item__main-ico--big svg {
  font-size: 40px;
}

@media only screen and (max-width: 1023px) {
  .rates-item__inner {
    text-align: left;
  }
}

.rates-item__inner p > span {
  font-size: 22px;
  color: #5a354b;
}

.rates-item__value {
  font-size: 35px;
  color: #5a354b;
  margin-bottom: 0px;
  line-height: 1;
}

.rates-item__value span {
  font-size: 20px;
}

.rates-item__value svg {
  font-size: 12px;
  fill: #5a354b;
}

.rates-item__text {
  font-size: 15px;
  color: #616161;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
}

.right-now {
  margin-bottom: 324px;
}

@media only screen and (max-width: 1023px) {
  .right-now {
    margin-bottom: 200px;
  }
}

.right-now__inner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
}

.right-now__text-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 56px;
}

@media only screen and (max-width: 1023px) {
  .right-now__text-wrap {
    display: block;
    text-align: center;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 539px) {
  .right-now__text-wrap {
    margin-bottom: 35px;
  }
}

.right-now__arrow {
  margin-bottom: 80px;
}

@media only screen and (max-width: 1023px) {
  .right-now__arrow {
    margin-bottom: 60px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 539px) {
  .right-now__arrow {
    margin-bottom: 35px;
  }
}

.right-now__big-text {
  margin-right: 26px;
}

@media only screen and (max-width: 1023px) {
  .right-now__big-text {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 539px) {
  .right-now__big-text {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1023px) {
  .right-now__small-text span {
    display: inline;
  }
}

@media only screen and (max-width: 1023px) {
  .right-now__small-text strong {
    display: none;
  }
}

@media only screen and (max-width: 539px) {
  .right-now__small-text {
    font-size: 16px;
  }
}

.right-now__mob-text {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .right-now__mob-text {
    display: block;
  }
}

.route-delivery {
  position: relative;
  margin-bottom: 350px;
}

@media only screen and (max-width: 1023px) {
  .route-delivery {
    margin-bottom: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .route-delivery {
    margin-bottom: 200px;
  }
}

@media only screen and (max-width: 1023px) {
  .route-delivery__sub-title {
    margin-bottom: 20px;
  }
}

.route-delivery__sub-text {
  text-align: center;
  margin-bottom: 92px;
}

@media only screen and (max-width: 1023px) {
  .route-delivery__sub-text {
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 1023px) {
  .route-delivery .number-plate {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .route-delivery .number-plate {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .route-delivery .number-plate__imgs {
    top: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .route-delivery .number-plate__imgs {
    top: 40%;
  }
}

@media only screen and (max-width: 1239px) {
  .route-delivery .number-plate__imgs img {
    max-width: 100vw;
    width: auto;
  }
}

@media only screen and (max-width: 767px) {
  .route-delivery .number-plate__imgs img {
    width: 130vw;
  }
}

.tell-us {
  margin-bottom: 296px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 1023px) {
  .tell-us {
    margin-bottom: 220px;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .tell-us {
    margin-bottom: 180px;
  }
}

.tell-us__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 1239px) {
  .tell-us__inner {
    max-width: 990px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1023px) {
  .tell-us__inner {
    position: relative;
    display: block;
    padding-top: 150px;
    max-width: 700px;
    min-height: 528px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .tell-us__inner {
    min-height: 530px;
    max-width: 520px;
  }
}

@media only screen and (max-width: 539px) {
  .tell-us__inner {
    min-height: 0;
  }
}

.tell-us__text-wrap {
  position: relative;
  width: 550px;
}

@media only screen and (max-width: 1239px) {
  .tell-us__text-wrap {
    width: 420px;
    flex-shrink: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .tell-us__text-wrap {
    width: 100%;
    margin-bottom: 46px;
  }
}

@media only screen and (max-width: 767px) {
  .tell-us__text-wrap {
    margin-bottom: 25px;
  }
}

.tell-us__form-wrap {
  position: relative;
  width: 650px;
  padding-top: 115px;
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (max-width: 1239px) {
  .tell-us__form-wrap {
    width: auto;
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .tell-us__form-wrap {
    position: static;
    width: 100%;
    padding-top: 0;
  }
}

@media only screen and (max-width: 1239px) {
  .tell-us__big-text {
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 1023px) {
  .tell-us__big-text {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .tell-us__big-text {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .tell-us__big-text span small {
    display: none;
  }
}

.tell-us__big-text > small {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .tell-us__big-text > small {
    display: block;
    margin-top: 10px;
    max-width: 100%;
  }
}

.tell-us__megaphone {
  position: absolute;
  left: 100%;
  top: -50px;
}

@media only screen and (max-width: 1239px) {
  .tell-us__megaphone {
    left: calc(100% + 55px);
  }
}

@media only screen and (max-width: 1023px) {
  .tell-us__megaphone {
    top: -150px;
    left: 50%;
    margin-left: -63px;
  }
}

.tell-us__megaphone svg {
  width: 109px;
  height: 120px;
}

@media only screen and (max-width: 539px) {
  .tell-us__line {
    display: none;
  }
}

.tell-us__line--top {
  top: 28px;
  left: 76px;
  width: 76px;
}

@media only screen and (max-width: 1239px) {
  .tell-us__line--top {
    left: 122px;
  }
}

@media only screen and (max-width: 1023px) {
  .tell-us__line--top {
    width: 266px;
    top: 50px;
    left: 0;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .tell-us__line--top {
    width: 177px;
  }
}

.tell-us__line--top-simple {
  top: 28px;
  width: 66px;
  left: -70px;
}

@media only screen and (max-width: 1239px) {
  .tell-us__line--top-simple {
    left: -30px;
  }
}

@media only screen and (max-width: 1023px) {
  .tell-us__line--top-simple {
    width: 266px;
    top: 50px;
    left: auto;
    right: 0;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .tell-us__line--top-simple {
    width: 177px;
  }
}

.tell-us__line--bottom {
  top: calc(100% + 15px);
  left: -181px;
  width: 615px;
}

@media only screen and (max-width: 1239px) {
  .tell-us__line--bottom {
    top: calc(100% + 2px);
  }
}

@media only screen and (max-width: 1023px) {
  .tell-us__line--bottom {
    display: none;
  }
}

.tell-us__tooltip {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(100% + 12px);
  text-align: center;
  user-select: none;
  transition: opacity 0.4s;
  opacity: 0;
}

.is-touch .tell-us__tooltip {
  display: none;
}

.tell-us__tooltip img {
  width: 100%;
}

.tell-us__tooltip span {
  display: block;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 18px;
}

.tell-us .form {
  position: relative;
}

.tell-us .form__field {
  position: relative;
}

.no-touch .tell-us .form__field:hover .tell-us__tooltip {
  opacity: 1;
}

@media only screen and (max-width: 1023px) {
  .tell-us .form__field {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .tell-us .form__field {
    margin-bottom: 28px;
  }
}

@media only screen and (max-width: 539px) {
  .tell-us .form__field {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .tell-us .form__line {
    display: block;
  }
}

.tell-us .btn {
  min-width: 230px;
}

.better {
  position: relative;
}

.better-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.better-list .better-block {
  width: 33.3333%;
}

@media only screen and (max-width: 1023px) {
  .better-list .better-block {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .better-list .better-block {
    width: 100%;
  }
}

.better-block {
  display: flex;
  margin-bottom: 82px;
}

@media only screen and (max-width: 1023px) {
  .better-block {
    margin-bottom: 48px;
  }
}

.better-block__icon {
  width: 67px;
  flex-grow: 0;
  flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
  .better-block__icon {
    width: 52px;
  }
}

.better-block__icon svg {
  fill: #ea242e;
  font-size: 46px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .better-block__icon svg {
    font-size: 40px;
  }
}

.better-block__inner {
  padding-right: 10px;
  max-width: 295px;
}

@media only screen and (max-width: 1023px) {
  .better-block__inner {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .better-block__inner {
    padding-right: 0;
    max-width: 100%;
  }
}

.better-block__head {
  font-family: Intro-Black, Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.better-block__text {
  font-size: 15px;
}

.better-block.js-better-list-item {
  opacity: 0;
}

.main-delivery {
  margin-top: 35vw;
}

@media only screen and (min-width: 2000px) {
  .main-delivery {
    margin-top: 44vw;
  }
}

@media only screen and (max-width: 1023px) {
  .main-delivery {
    margin-top: 19vw;
  }
}

@media only screen and (max-width: 1023px) {
  .is-phone .main-delivery {
    margin-top: 12vw;
  }
}

@media only screen and (max-width: 767px) {
  .is-phone .main-delivery {
    margin-top: 10vw;
  }
}

@media only screen and (max-width: 539px) {
  .is-phone .main-delivery {
    margin-top: 7vw;
  }
}

.main-delivery__inner {
  display: flex;
  align-items: flex-end;
  min-height: 230px;
  margin-bottom: 180px;
}

@media only screen and (min-width: 2000px) {
  .main-delivery__inner {
    margin-bottom: 10.5vw;
  }
}

@media only screen and (max-width: 1023px) {
  .main-delivery__inner {
    margin-bottom: 63vw;
  }
}

@media only screen and (max-width: 1023px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 49vw;
  }
}

@media only screen and (max-width: 870px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 48vw;
  }
}

@media only screen and (max-width: 820px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 51vw;
  }
}

@media only screen and (max-width: 767px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 56vw;
  }
}

@media only screen and (max-width: 699px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 58vw;
  }
}

@media only screen and (max-width: 619px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 61vw;
  }
}

@media only screen and (max-width: 549px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 68vw;
  }
}

@media only screen and (max-width: 489px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 72vw;
  }
}

@media only screen and (max-width: 449px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 76vw;
  }
}

@media only screen and (max-width: 410px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 79vw;
  }
}

@media only screen and (max-width: 390px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 85vw;
  }
}

@media only screen and (max-width: 370px) {
  .is-phone .main-delivery__inner {
    margin-bottom: 90vw;
  }
}

@media only screen and (max-width: 1023px) {
  .main-delivery__text {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1023px) {
  .is-phone .main-delivery__text {
    font-size: 5.5vw;
  }
}

@media only screen and (max-width: 539px) {
  .is-phone .main-delivery__text {
    font-size: 7vw;
  }
}

@media only screen and (max-width: 449px) {
  .is-phone .main-delivery__text {
    font-size: 8vw;
  }
}

@media only screen and (max-width: 1023px) {
  .main-delivery .waves {
    transform-origin: 50% 50%;
    top: 0;
    transform: translateY(-82px);
  }
}

@media only screen and (max-width: 1023px) {
  .is-phone .main-delivery .waves {
    transform: translateY(-3.5vw);
  }
}

@media only screen and (max-width: 767px) {
  .is-phone .main-delivery .waves {
    transform: translateY(-1.5vw);
  }
}

@media only screen and (max-width: 539px) {
  .is-phone .main-delivery .waves {
    transform: translateY(3vw);
  }
}

@media only screen and (max-width: 399px) {
  .is-phone .main-delivery .waves {
    transform: translateY(8vw);
  }
}

@media only screen and (min-width: 430px) and (orientation: portrait) {
  .is-phone .main-delivery .waves__inner {
    left: 54%;
  }
}

.delivery-nav {
  position: relative;
  top: -20px;
  margin-bottom: 230px;
}

@media only screen and (max-width: 1023px) {
  .delivery-nav {
    margin-bottom: 210px;
  }
}

.delivery-nav__wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.delivery-nav__item {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .delivery-nav__item:nth-of-type(1), .delivery-nav__item:nth-of-type(2) {
    width: 20%;
  }
}

@media only screen and (max-width: 767px) {
  .delivery-nav__item {
    width: 30%;
  }
}

.delivery-nav__link {
  position: relative;
  display: inline-block;
  padding: 0 25px;
  transition: opacity 0.3s;
}

@media only screen and (max-width: 1023px) {
  .delivery-nav__link {
    padding: 0 17px;
  }
}

@media only screen and (max-width: 767px) {
  .delivery-nav__link {
    display: inline-block;
    padding: 0 5px;
  }
}

@media only screen and (max-width: 370px) {
  .delivery-nav__link {
    padding: 0 7px;
  }
}

.no-touch .delivery-nav__link:not(.is-active):hover {
  opacity: 0.5;
}

.delivery-nav__link span {
  display: block;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #5a354b;
  transition: color 0.3s;
}

@media only screen and (max-width: 1023px) {
  .delivery-nav__link span {
    line-height: 1;
  }
}

@media only screen and (max-width: 767px) {
  .delivery-nav__link span {
    max-width: 120px;
    min-height: 33px;
    margin: 0 auto;
    font-size: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .delivery-nav__link span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 370px) {
  .delivery-nav__link span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1023px) {
  .delivery-nav__link span.delivery-nav__hidden-tablet {
    display: none;
  }
}

.delivery-nav__link span.delivery-nav__show-tablet {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .delivery-nav__link span.delivery-nav__show-tablet {
    display: block;
  }
}

.delivery-nav__link.is-active .delivery-nav__icon svg {
  fill: #ea242e;
}

.delivery-nav__link.is-active .delivery-nav__decor {
  opacity: 1;
}

.delivery-nav__link.is-active span {
  color: #ea242e;
}

.delivery-nav__icon {
  display: block;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .delivery-nav__icon {
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 370px) {
  .delivery-nav__icon {
    height: 30px;
  }
}

.delivery-nav__icon svg {
  font-size: 40px;
  fill: #5a354b;
  transition: fill 0.3s;
}

@media only screen and (max-width: 539px) {
  .delivery-nav__icon svg {
    font-size: 34px;
  }
}

@media only screen and (max-width: 370px) {
  .delivery-nav__icon svg {
    font-size: 30px;
  }
}

.delivery-nav__decor {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 100%);
  height: 20px;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

@media only screen and (max-width: 767px) {
  .delivery-nav__decor {
    bottom: 18px;
  }
}

@media only screen and (max-width: 449px) {
  .delivery-nav__decor {
    bottom: 21px;
  }
}

@media only screen and (max-width: 370px) {
  .delivery-nav__decor {
    bottom: 23px;
    height: 16px;
  }
}

.delivery-nav__decor:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 200%;
  border: 1px dotted #ea242e;
  border-radius: 30px;
}

.delivery-nav .bg-wave {
  top: 30%;
  height: 318px;
}

@media only screen and (max-width: 549px) {
  .delivery-nav .bg-wave {
    top: 12%;
  }
}

.delivery-nav .bg-wave__inner {
  width: 100%;
  opacity: 1;
}

.carry {
  margin-bottom: 290px;
}

@media only screen and (max-width: 1023px) {
  .carry {
    margin-bottom: 215px;
  }
}

@media only screen and (max-width: 1023px) {
  .carry__title {
    margin-bottom: 80px;
  }
}

.carry__text {
  font-size: 14px;
  margin-bottom: 26px;
  line-height: 1.5;
}

.carry__descr {
  font-size: 14px;
  color: #a4a4a4;
}

.ready {
  margin-bottom: 291px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 1023px) {
  .ready {
    margin-bottom: 240px;
    padding-bottom: 0;
  }
}

.ready__inner {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

@media only screen and (max-width: 1239px) {
  .ready__inner {
    max-width: 984px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1023px) {
  .ready__inner {
    display: block;
    max-width: 660px;
  }
}

.ready__text-wrap {
  position: relative;
  width: 600px;
}

@media only screen and (max-width: 1239px) {
  .ready__text-wrap {
    width: 505px;
  }
}

@media only screen and (max-width: 1023px) {
  .ready__text-wrap {
    width: 100%;
    margin-bottom: 38px;
  }
}

@media only screen and (max-width: 539px) {
  .ready__text-wrap {
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) {
  .ready__big-text {
    margin-bottom: 20px;
    font-size: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .ready__big-text {
    font-size: 60px;
  }
}

@media only screen and (max-width: 1239px) {
  .ready__big-text small {
    max-width: 225px;
  }
}

@media only screen and (max-width: 1023px) {
  .ready__big-text small {
    font-size: 26px;
    max-width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .ready__big-text small {
    font-size: 24px;
    max-width: 242px;
  }
}

@media only screen and (max-width: 539px) {
  .ready__big-text small {
    max-width: 100%;
    margin-top: 10px;
    font-size: 20px;
  }
}

.ready__form-wrap {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 600px;
  padding-left: 50px;
}

@media only screen and (max-width: 1023px) {
  .ready__form-wrap {
    width: 100%;
    padding: 0;
  }
}

.ready__car {
  position: absolute;
  left: 108%;
  top: 0px;
}

@media only screen and (max-width: 1239px) {
  .ready__car {
    top: -20px;
  }
}

@media only screen and (max-width: 1023px) {
  .ready__car {
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .ready__car {
    position: static;
    margin-bottom: 20px;
    text-align: center;
  }
}

.ready__car svg {
  width: 135px;
  height: 92px;
  fill: #fff;
}

@media only screen and (max-width: 767px) {
  .ready__car svg {
    width: 115px;
    height: 78px;
  }
}

.ready__line {
  top: calc(100% + 15px);
  left: 146px;
  width: 624px;
}

@media only screen and (max-width: 1239px) {
  .ready__line {
    left: 112px;
  }
}

@media only screen and (max-width: 1023px) {
  .ready__line {
    display: none;
  }
}

.ready__form {
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .ready__form {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1023px) {
  .ready .form {
    text-align: center;
  }
}

@media only screen and (max-width: 1023px) {
  .ready .form__line {
    display: block;
  }
}

@media only screen and (max-width: 1023px) {
  .ready .form__field {
    margin-right: 0;
    margin-bottom: 38px;
  }
}

@media only screen and (max-width: 539px) {
  .ready .form__field {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .ready .text--spec-36 {
    max-width: 500px;
    font-size: 36px;
  }
}

@media only screen and (max-width: 539px) {
  .ready .text--spec-36 {
    max-width: 100%;
    font-size: 26px;
  }
}

.media {
  margin-bottom: 400px;
}

@media only screen and (max-width: 1239px) {
  .media {
    margin-bottom: 300px;
  }
}

@media only screen and (max-width: 1023px) {
  .media {
    margin-bottom: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .media {
    margin-bottom: 200px;
  }
}

@media only screen and (max-width: 539px) {
  .media {
    margin-bottom: 160px;
  }
}

@media only screen and (max-width: 1023px) {
  .media__list-wrapper {
    overflow-x: auto;
  }
}

.media__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .media__list {
    width: 800px;
    flex-wrap: nowrap;
  }
}

.media__list-item {
  margin: 0 20px 20px;
  flex-shrink: 1;
}

.media__list-item img {
  max-height: 50px;
  max-width: 100%;
}

@media only screen and (max-width: 1239px) {
  .media__list-item img {
    max-height: 36px;
  }
}

.time {
  position: relative;
  margin-bottom: 284px;
}

@media only screen and (max-width: 1239px) {
  .time {
    margin-bottom: 240px;
  }
}

@media only screen and (max-width: 1023px) {
  .time {
    margin-bottom: 200px;
  }
}

@media only screen and (max-width: 539px) {
  .time {
    margin-bottom: 180px;
  }
}

.time__bg-wave {
  height: 1677px;
  top: 45%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 1239px) {
  .time__bg-wave {
    height: 1500px;
  }
}

@media only screen and (max-width: 1023px) {
  .time__bg-wave {
    top: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .time__bg-wave {
    display: none;
    height: 2000px;
  }
}

@media only screen and (max-width: 539px) {
  .time__bg-wave {
    height: 1400px;
  }
}

.pre-footer {
  margin-bottom: 300px;
}

@media only screen and (max-width: 1023px) {
  .pre-footer {
    margin-bottom: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .pre-footer {
    margin-bottom: 250px;
  }
}

@media only screen and (max-width: 539px) {
  .pre-footer {
    margin-bottom: 170px;
  }
}

.pre-footer__sub-title {
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1023px) {
  .pre-footer__sub-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 539px) {
  .pre-footer__sub-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .pre-footer__sub-title span {
    display: block;
  }
}

.pre-footer__sub-title span:not(last-of-type) {
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .pre-footer .cols {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .pre-footer .col--50 {
    width: 100%;
    padding: 0;
  }
}

.footer {
  position: relative;
}

@media only screen and (max-width: 1023px) {
  .footer {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}

@media only screen and (max-width: 539px) {
  .footer {
    padding-bottom: 40px;
  }
}

.footer__bg {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  z-index: -1;
}

@media only screen and (max-width: 1023px) {
  .footer__bg {
    height: 180%;
  }
}

@media only screen and (max-width: 539px) {
  .footer__bg {
    height: 190%;
  }
}

.footer__bg-inner {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  min-width: 1920px;
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  .footer__bg-inner {
    min-width: 1px;
    width: auto;
    height: 100%;
  }
}

.footer__bg-inner img {
  width: 100%;
  height: auto;
  max-height: 1112px;
}

@media only screen and (max-width: 1023px) {
  .footer__bg-inner img {
    width: auto;
    max-height: none;
    height: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__inner {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 539px) {
  .footer__inner {
    display: block;
  }
}

.footer__top {
  padding-bottom: 40px;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
  margin-bottom: 102px;
}

@media only screen and (max-width: 1023px) {
  .footer__top {
    width: 50%;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
    border-right: 1px dotted rgba(255, 255, 255, 0.3);
    padding-right: 25px;
  }
}

@media only screen and (max-width: 539px) {
  .footer__top {
    width: 100%;
    margin-bottom: 25px;
    padding: 0;
    border: none;
  }
}

.footer__bottom {
  padding-bottom: 77px;
}

@media only screen and (max-width: 1023px) {
  .footer__bottom {
    width: 50%;
    padding-bottom: 0;
    padding-left: 25px;
  }
}

@media only screen and (max-width: 539px) {
  .footer__bottom {
    width: 100%;
    padding: 0;
  }
}

.footer__row {
  display: flex;
  align-items: flex-start;
}

@media only screen and (max-width: 1023px) {
  .footer__row {
    display: block;
  }
}

.footer__row .fblock {
  width: 512px;
}

@media only screen and (max-width: 1023px) {
  .footer__row .fblock:last-of-type {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__row .fblock {
    width: 100%;
  }
}

.footer__row .fblock:nth-of-type(2n) {
  width: 424px;
}

@media only screen and (max-width: 1023px) {
  .footer__row .fblock:nth-of-type(2n) {
    width: 100%;
  }
}

.footer__row .fblock:nth-of-type(3n) {
  width: 264px;
}

@media only screen and (max-width: 1023px) {
  .footer__row .fblock:nth-of-type(3n) {
    width: 100%;
  }
}

.fblock {
  display: flex;
  align-items: flex-start;
  padding-right: 10px;
  margin-bottom: 50px;
  color: #fff;
}

@media only screen and (max-width: 1023px) {
  .fblock {
    min-height: 136px;
  }
}

@media only screen and (max-width: 767px) {
  .fblock {
    min-height: 120px;
  }
}

@media only screen and (max-width: 539px) {
  .fblock {
    min-height: 1px;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .fblock:first-of-type {
    min-height: 150px;
  }
}

@media only screen and (max-width: 539px) {
  .fblock:first-of-type {
    min-height: 1px;
  }
}

.fblock__icon {
  position: relative;
  top: 1px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}

.fblock__icon img {
  max-width: 30px;
  height: auto;
  max-height: 30px;
}

.fblock__spec-img {
  position: relative;
  top: -3px;
}

.fblock__title {
  font-family: Intro-Black, Arial, Helvetica, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.fblock__title--mb-big {
  margin-bottom: 15px;
}

.fblock__text {
  font-size: 16px;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 1023px) {
  .fblock__text {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 539px) {
  .fblock__text {
    padding-right: 0;
  }
}

.fblock__text--max-270 {
  max-width: 270px;
}

@media only screen and (max-width: 1023px) {
  .fblock__text--max-270 {
    max-width: 100%;
  }
}

.fblock__text a {
  color: inherit;
  border-bottom: 1px dashed #fff;
  transition: opacity 0.3s;
}

.no-touch .fblock__text a:hover {
  opacity: 0.5;
}

.fblock__text p:not(:last-of-type) {
  margin-bottom: 10px;
}

.fblock__icons {
  display: flex;
  align-items: center;
  font-size: 0;
  height: 67px;
}

@media only screen and (max-width: 767px) {
  .fblock__icons {
    height: auto;
  }
}

.fblock__icons-item:not(:last-of-type) {
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .fblock__icons-item:not(:last-of-type) {
    margin-right: 15px;
  }
}

.fblock__icons-item--img img {
  max-height: 67px;
}

@media only screen and (max-width: 1023px) {
  .fblock__icons-item--img img {
    max-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .fblock__icons-item--img img {
    max-height: 30px;
  }
}

.fblock__icons-item:not(.fblock__icons-item--img) img {
  height: 38px;
}

@media only screen and (max-width: 767px) {
  .fblock__icons-item:not(.fblock__icons-item--img) img {
    height: 28px;
  }
}

.fblock__icons-item i {
  display: block;
}

.fblock__icons a {
  transition: opacity 0.3s;
}

.no-touch .fblock__icons a:hover {
  opacity: 0.07;
}

.icon-vk {
  fill: #436eab;
  font-size: 28px;
}

@media only screen and (max-width: 767px) {
  .icon-vk {
    font-size: 22px;
  }
}

.icon-facebook, .icon-twitter, .icon-viber, .icon-chat {
  font-size: 32px;
}

@media only screen and (max-width: 767px) {
  .icon-facebook, .icon-twitter, .icon-viber, .icon-chat {
    font-size: 26px;
  }
}

.icon-facebook {
  fill: #385c8e;
}

.icon-twitter {
  fill: #03a9f4;
}

.icon-viber {
  fill: #a554e4;
  font-size: 41px;
}

@media only screen and (max-width: 767px) {
  .icon-viber {
    font-size: 30px;
  }
}

.icon-chat {
  fill: #7baf74;
  font-size: 44px;
}

@media only screen and (max-width: 767px) {
  .icon-chat {
    font-size: 30px;
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  transition: opacity 0.3s ease-in-out;
}

.modal:not(.is-open) {
  opacity: 0;
  visibility: hidden;
  z-index: -999;
}

.modal__container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}

.modal__overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(255, 255, 255, 0.95);
}

@media only screen and (max-width: 1499px) {
  .modal__overlay:before {
    max-width: 100%;
    height: 100%;
    padding: 0;
  }
}

.modal__inner {
  position: relative;
  padding: 1em 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 599px) {
  .modal__inner {
    width: 100%;
  }
}

.modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  min-height: 46px;
  line-height: 0;
  margin-bottom: 1.5em;
  border-radius: 50%;
  background-color: #ee2025;
  font-size: 20px;
  color: #fff;
}

.modal__close .icon {
  line-height: 0;
}

@media only screen and (max-width: 539px) {
  .modal__close {
    width: 30px;
    min-height: 30px;
    font-size: 14px;
  }
}

.modal__content {
  flex-grow: 1;
  width: 100%;
  max-width: 850px;
  padding: 40px 60px 80px;
  box-shadow: 0 0 40px rgba(48, 48, 48, 0.19);
  background-color: #fff;
}

.modal__content--xl {
  max-width: 920px;
  padding: 40px 60px 50px;
}

@media only screen and (max-width: 767px) {
  .modal__content {
    max-width: 700px;
    padding: 40px 40px 80px;
  }
}

@media only screen and (max-width: 599px) {
  .modal__content {
    max-width: 520px;
  }
}

@media only screen and (max-width: 449px) {
  .modal__content {
    padding: 15px 15px 30px;
  }
}

.modal__scrollbox {
  max-height: 350px;
  overflow-y: auto;
  margin-right: -30px;
  padding-right: 30px;
  margin-bottom: -20px;
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.modal__scrollbox p + p {
  margin-top: 1em;
}

.modal__map {
  max-width: 800px;
  width: 100%;
  height: 344px;
  background-color: #eee;
  border-radius: 7px;
  border: 1px solid #e8e8e8;
  overflow: hidden;
}

.balloon-content {
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #363636;
}

.balloon-link {
  position: relative;
  display: inline-block;
  font-size: 12px;
  color: #363636;
}

.balloon-link:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px dashed #363636;
}

.no-touch .balloon-link:hover {
  color: #000;
}
