.title {
  font-style: normal;
  text-transform: uppercase;
  @include iblack;
  &--xl, &--xl-modal {
    color: $red6;
    font-size: 72px;
    @include ilight;
    font-weight: 300;
    strong {
      font-weight: normal;
      @include iblack; } }
  &--xl {
    @include lm {
      font-size: 66px; }
    @include md {
      font-size: 60px;
      line-height: 1; }
    @include sm {
      font-size: 48px; }
    @include xxs {
      font-size: 30px; } }
  &--xl-modal {
    @include sm {
      font-size: 60px; }
    @include xs {
      font-size: 50px; }
    @include xxs {
      font-size: 30px; } }
  &--l {
    font-size: 67px;
    line-height: 1;
    @include l {
      font-size: 57px; }
    @include lm {
      font-size: 44px; }
    @include md {
      font-size: 40px; }
    @include sm {
      font-size: 30px; }
    @include xxs {
      font-size: 24px; }
    @include xxxs {
      font-size: 20px; } }
  &--m {
    font-size: 34px;
    @include ibold;
    letter-spacing: 1.1px;
    @include sm {
      font-size: 28px; } }
  &--white {
    color: $white; }
  &--red-dark {
    color: $red3; }
  &--grad {
    background: linear-gradient(to right, $red6, $red-grad-right);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include md {
      line-height: 1.33; } }
  &--right {
    text-align: right; }
  &--center {
    text-align: center; }
  &--mb-m {
    margin-bottom: 22px; }
  &--mb-lg {
    margin-bottom: 61px;
    @include sm {
      margin-bottom: 50px; }
    @include xxs {
      margin-bottom: 40px; } } }
