.who {
  position: relative;
  z-index: 3;
  padding-top: 60px;
  margin-bottom: 328px;
  pointer-events: none;
  @include ld {
    margin-bottom: 220px; }
  @include md {
    padding-top: 120px; }
  @include xxs {
    padding-top: 80px; }
  &__title {
    margin-bottom: 85px;
    @include md {
      margin-bottom: 70px; }
    @include sm {
      margin-bottom: 60px; } }
  &__cols {
    display: flex;
    margin-bottom: 72px;
    @include md {
      flex-wrap: wrap; }
    @include xxs {
      display: block; }
    &--justify {
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 0;
      @include md {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto; }
      @include xxs {
        text-align: center; } } }
  &__col {
    flex-grow: 0;
    br {
      @include lm {
        display: none; } }
    @include md {
      text-align: center; }
    &--left {
      width: percentage(666/1200);
      text-align: right;
      flex-grow: 1;
      margin-right: 100px;
      @include lm {
        margin-right: 60px; }
      @include md {
        width: 100%;
        margin-right: 0;
        text-align: center;
        margin-bottom: 20px; } }
    &--right {
      width: percentage(430/1200);
      @include md {
        width: 100%; } }
    &--left, &--right {
      @include md {
        width: 100%; } }
    &--sm {
      width: 230px;
      flex-grow: 0;
      @include md {
        width: 250px; }
      @include sm {
        width: 240px; }
      @include xxs {
        width: 260px;
        display: inline-block;
        vertical-align: top; } } } }
.who-item {
  @include md {
    margin-bottom: 70px; }
  @include sm {
    margin-bottom: 50px; }
  &__img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 38px;
    height: 210px;
    @include md {
      height: 190px;
      margin-bottom: 36px; }
    @include sm {
      max-width: 220px;
      margin-left: auto;
      margin-right: auto; }
    svg {
      max-width: 100%;
      max-height: 100%; } }

  &__text {
    border-top: 1px dotted $border-color;
    padding-top: 20px;
    text-align: center;
    font-size: 16px;
    @include mreg; } }

.anim-scene-item, .auto {
  position: absolute;
  &1 {
    top: 3%;
    left: 0px;
    width: 160px;
    height: 120px; }
  &2 {
    top: 13%;
    right: 2%;
    width: 50%;
    height: 51%; }
  &3 {
    bottom: 7%;
    left: 5%;
    width: 43%;
    height: 43%; }
  &4 {
    bottom: 9%;
    right: 0;
    width: 40%;
    height: 40%; }

  &.bigcar {
    top: 10%;
    left: 4%;
    width: 80%;
    height: 80%; }
  &.bigcar-box {
    top: 24%;
    left: 39%;
    width: 40%;
    height: 40%; }
  &.loader {
    left: 57%;
    top: 51%;
    width: 30%;
    height: 30%; }
  &.loader-box {
    top: 51%;
    left: 71%;
    width: 15%;
    height: 15%; }
  &.static-loader {
    right: 3%;
    top: 42%;
    width: 21%;
    height: 21%; }
  &.static-loader-box {
    top: 36%;
    right: 10%;
    width: 10%;
    height: 16%; }
  &.scanner {
    top: 27%;
    right: 4%;
    width: 34%;
    height: 57%; }
  &.box1, &.box2, &.box3, &.box4 {
    top: 50%;
    left: 50%; }
  &.box1 {
    width: 35%;
    height: 40%;
    .is-phone & {
      top: 38%;
      left: 28%; } }
  &.box2 {
    width: 25%;
    height: 24%;
    .is-phone & {
      top: 20%;
      left: 32%; } }
  &.box3 {
    width: 10%;
    height: 11%;
    .is-phone & {
      top: 16%;
      right: 38%; } }
  &.box4 {
    height: 12%;
    width: 16%;
    .is-phone & {
      top: 63%;
      left: 5%; } }
  &.courier1 {
    top: 18%;
    left: 10%;
    height: 60%;
    width: 43%; }
  &.courier2 {
    top: 18%;
    height: 50%;
    left: 37%;
    width: 37%; }
  &.courier3 {
    top: 37%;
    left: 47%;
    height: 45%;
    width: 43%; } }
