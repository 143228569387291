.comet-wrap {
  $this: &;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 1920px;
  height: 920px;
  pointer-events: none;
  &__left {
    left: 0;
    bottom: 0; }
  &__right {
    right: 0;
    top: 0; }
  &__left, &__right {
    position: absolute;
    font-size: 0;
    // background: rgba($red4, 0.3)
    img, svg {
      width: 785px;
      height: 547px;
      opacity: 0.5; } }
  &--route-delivery, &--how-work {
    height: 560px;
    top: -86px;
    #{$this}__left {
      bottom: -180px; }
    #{$this}__right {
      top: -180px; } }
  &--route-delivery {}
  &--better {}
  &--how-work {}
  &--small-view {
    #{$this}__left {
      left: -400px; }
    #{$this}__right {
      right: -400px; } } }
