@function em($size, $context: 16) {
  @return ($size/$context) * 1em; }

@function img-url($image) {
  @return url('../img/#{$image}'); }

@mixin image($image) {
  background-image: img-url($image); }

@mixin trans($what: all, $dur: 0.2s, $easing: ease) {
  transition: $what $dur $easing; }

@mixin smooth-scroll {
  -webkit-overflow-scrolling: touch; }

@mixin smooth-font {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@mixin gpu {
  transform: translate3d(0,0,0); }
@mixin xxl {
  @include r(2999) {
    @content; } }
@mixin xl {
  @include r(1919) {
    @content; } }

@mixin l {
  @include r(1499) {
    @content; } }

@mixin ld {
  @include r(1279) {
    @content; } }

@mixin lm {
  @include r(1239) {
    @content; } }

@mixin md {
  @include r(1023) {
    @content; } }

@mixin sm {
  @include r(767) {
    @content; } }

@mixin xs {
  @include r(599) {
    @content; } }
@mixin xxs {
  @include r(539) {
    @content; } }
@mixin xxxs {
  @include r(449) {
    @content; } }
@mixin extra-xs {
  @include r(370) {
    @content; } }
@mixin mdmin {
  @include rmin(1024) {
    @content; } }
@mixin xxlmin {
  @include rmin(3000) {
    @content; } }
@mixin xlmin {
  @include rmin(1920) {
    @content; } }
@mixin k2min {
  @include rmin(2000) {
    @content; } }
@mixin xlmin {
  @include rmin(1920) {
    @content; } }
