.bg-wave {
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -1;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  font-size: 0;
  transform: translateY(-50%);
  transform-origin: 50% 50%;
  &__inner {
    position: absolute;
    top: 0%;
    left: 50%;
    width: 110%;
    height: 100%;
    min-width: 1920px;
    opacity: 0;
    transform: translateX(-50%);
    @include is-phone {
      opacity: 1; }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; } } }
