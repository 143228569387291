$white: #fff;
$black: #000;
$black-main: #262626;
$blue: #502e5e;
$gray: #959495;
$gray2: #616161;
$gray3: #a4a4a4;
$gray4: #bfbfbf;
$gray5: #e8e8e8;
$red: #f0291f;
$red2: #6c3649;
$red3: #5a354b;
$red4: #ea242e;
$red5: #664358;
$red6: #b01a24;
$yellow: #ffb900;

$red-grad-right: #e9202e;


$hero-bg: #f6c9b9;
$hero-bg-bottom: #ba6cb8;

$border-color: #62384b;

$balloonTextColor: #363636;
