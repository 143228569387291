.waves {
  position: absolute;
  left: 0%;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  &__inner {
    position: absolute;
    left: 50%;
    top: 0;
    min-width: 1920px;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    @include is-phone {
      min-width: 1024px; } }
  canvas {
    position: absolute;
    top: 0;
    left: -1%;
    width: 100%;
    height: 100%;
    width: 102%;
    @include rmin(1800) {
      width: 105%;
      left: -2.5%; } } }
