.how-work {
  position: relative;
  margin-bottom: 332px;
  @include md {
    margin-bottom: 200px; }
  @include sm {
    margin-bottom: 180px; }
  &__title {}
  &__sub-title {}
  &__sub-text {
    margin-bottom: 54px;
    text-align: center; }
  &__title, &__sub-title, &__sub-text {
    position: relative;
    z-index: 1; }
  &__tabs {
    margin-bottom: 66px;
    @include sm {
      margin-bottom: 58px; } }
  &__btn {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: top;
    margin: 0 32px 55px;
    font-size: 18px;
    @include sm {
      line-height: 1.2; }
    @include xxs {
      margin: 0; }
    img {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 110%;
      height: auto;
      transform: translate(-50%, -20%); }
    i {
      position: absolute;
      top: 210%;
      left: 50%;
      transform: translate(-50%, 0%);
      display: block;
      font-size: 0; }
    svg {
      width: 10px;
      height: 6px;
      fill: $red4; }
    img, i {
      @include xxs {
        display: none; } } } }
