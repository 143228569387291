.date-pick {
  $this: &;
  position: relative;
  height: 100%;
  cursor: pointer;
  &__input {
    position: relative;
    z-index: 21;
    display: block;
    pointer-events: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s; }
  &.is-active {
    #{$this}__input {
       box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); } } }
.ui-datepicker-inline {
  display: none;
  font-size: 16px;
  position: absolute;
  top: calc(100% - 6px);
  left: 0;
  z-index: 20;
  background: $white;
  border-radius: 0 7px 7px 7px;
  padding: 27px 25px 7px;
  cursor: default;
  .ui-datepicker-header {
    position: relative; }
  .ui-datepicker-prev, .ui-datepicker-next {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 16px;
    height: 16px;
    font-size: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    cursor: pointer; }
  .ui-datepicker-next {
    right: 0;
    background-image: url('../img/calendar-arrow-next.svg'); }
  .ui-datepicker-prev {
    left: 0;
    background-image: url('../img/calendar-arrow-prev.svg'); }
  .ui-datepicker-title {
    position: relative;
    text-align: center;
    position: relative;
    padding-bottom: 12px;
    color: $red4;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      left: -25px;
      top: 100%;
      transform: translateY(-5px);
      height: 33px;
      width: calc(100% + 50px);
      background-color: #f6f4f4; } }
  .ui-datepicker-month {
    &:after {
      content: ','; } }
  td {
    a {
      color: $gray2;
      display: inline-block;
      margin-bottom: 11px;
      padding: 2px; }
    &.ui-datepicker-today {
      a {
        color: $red4; } }
    &.ui-datepicker-current-day {
      a {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          display: block;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: rgba(#eb212e, 0.2); } } }
    &.ui-datepicker-other-month {
      opacity: 0.5; } }
  tr {
    td, th {
      &:not(:last-child) {
        padding-right: 15px; } } }
  th {
    padding-bottom: 17px; } }
