.tabs {
  $this: &;
  &__nav {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 116px;
    @include md {
      margin-bottom: 60px; }
    @include xxs {
      display: block;
      max-width: 340px;
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
    &-separator {
      position: relative;
      margin: 0 8px;
      width: 90px;
      font-size: 0;
      height: 2px;
      @include sm {
        width: 20px; }
      @include xxs {
        display: none; }
      &-first-img, &-second-img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 2px;
        transition: opacity 0.3s;
        transform-origin: 50% 50%;
        @include sm {
          display: none; } }
      &-first-img {
        opacity: 0;
        transform: translate(-50%, -50%); }
      &-second-img {
        opacity: 1;
        transform: translate(-50%, -50%); } }
    &-item {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 15px 27px;
      border-radius: 30px;
      background: $white;
      &:not(:last-of-type) {
        @include xxs {
          margin-bottom: 20px; } }
      @include sm {
        padding: 17px 22px; }
      &:before, &:after {
        content: '';
        @include coverdiv;
        transition: 0.3s opacity;
        border-radius: 30px; }
      &:before {
        border: 1px solid $red3; }
      &:after {
        border: 1px solid $red4;
        opacity: 0; }
      &-arrow {
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%, 0%);
        z-index: 0;
        width: 100%;
        height: 5px;
        transition: 0.3s opacity;
        opacity: 0;
        overflow: hidden;
        &:before {
          content: '';
          position: absolute;
          z-index: 0;
          left: 0;
          top: 0;
          width: 100%;
          height: 1px;
          background: $red4; }
        &:after {
          content: '';
          position: absolute;
          z-index: 1;
          left: 50%;
          top: -1px;
          width: 8px;
          height: 8px;
          border: 1px solid $red4;
          background: $white;
          transform:  translate(-50%, -50%) rotate(45deg); } }
      span {
        position: relative;
        display: block;
        color: $red3;
        font-size: 18px;
        border-bottom: 1px dashed $red3;
        transition: 0.3s border-color, 0.3s color; }
      i {
        display: block;
        font-size: 0;
        margin-right: 8px;
        @include sm {
          display: none; } }
      svg {
        width: 23px;
        height: 28px;
        fill: $red3;
        transition: 0.3s fill; }
      img {
        position: absolute;
        bottom: 1px;
        left: 50%;
        transform: translate(-50%, 100%);
        width: 102%;
        height: auto;
        transition: 0.3s opacity;
        opacity: 0;
        font-size: 0; }
      &.is-active {
        &:before {
          opacity: 0; }
        &:after {
          opacity: 1; }
        img, #{$this}__nav-item-arrow {
          opacity: 1; }
        span {
          border-color: transparent;
          color: $red4; }
        svg {
          fill: $red4; }
        & + #{$this}__nav-separator {
          .tabs__nav-separator-first-img {
            opacity: 1; }
          .tabs__nav-separator-second-img {
            opacity: 0; } } } } }
  &__content {
    position: relative;
    z-index: 0; }
  &__item {
    &:not(.is-active) {
      display: none; } }
  &__nav-helper {
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0px;
    transform: translateY(100%);
    height: 5px;
    width: 100%;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 10px;
      width: 100%;
      border: 1px solid $gray4;
      border-radius: 7px; } }
  &--better {
    #{$this}__nav {
      margin-bottom: 103px;
      @include md {
        margin-bottom: 55px; } } }
  &--carry {
    width: 100%;
    #{$this}__mob-scroll-wrap {
      @include md;
      overflow-x: auto; }
    #{$this}__nav {
      margin-bottom: 44px;
      @include md {
        margin-bottom: 35px;
        width: 1024px; }
      @include sm {
        width: 860px; }
      @include xxs {
        display: flex;
        max-width: none; }
      &-item {
        z-index: 1;
        justify-content: center;
        border: none;
        padding: 0;
        height: 124px;
        padding-bottom: 10px;
        @include md {
          height: 80px; }
        @include xxs {
          margin-bottom: 0; }

        &:before, &:after {
          display: none; }
        i {
          display: block;
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translate(-50%, 0%);
          width: 10px;
          height: 6px;
          margin: 0;
          overflow: hidden;
          transition: opacity 0.4;
          opacity: 0;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 50%) rotate(45deg);
            display: block;
            width: 6px;
            height: 6px;
            border: 1px solid $gray4;
            border-radius: 0;
            background: $white; } }
        &:not(:last-of-type) {
          margin-right: 60px;
          @include md {
            margin-right: 40px; }
          @include xxs {
            margin-right: 20px; } }
        img {
          position: static;
          max-width: 100%;
          height: auto;
          max-height: 90%;
          transform: none;
          opacity: 1;
          @include md {
            max-height: 70px;
            max-width: none; } }
        &.is-active {
          i {
            opacity: 1; } }
        &.is-mob-hidden {
          @include r(600) {
            display: none; } } } } } }
