.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  @include mlight;
  color: $white;
  background-color: #ea242e;
  border-radius: 43px;
  border: none;
  appearance: none;
  padding: 28px 45px;
  @include xxs {
    padding: 20px 30px; }
  i {
    position: relative;
    top: 2px;
    display: block;
    margin-right: 10px; }
  svg {
    font-size: 24px;
    fill: $white; }
  &--anim {
    background-image: url('../img/btn-bg-waves.png');
    background-repeat: repeat;
    background-position: 90% 90%;
    transition: background-position 1s linear;
    @include hover {
      transition: background-position 6s linear;
      background-position: 15% 0%; } }
  &--shadow-l {
    box-shadow: 0 0 63.2px 4.8px rgba(204, 167, 129, 1);
    @include md {
      box-shadow: 0 0 63.2px 4.8px rgba(204, 167, 129, 0.7); } }
  &--light-shadow {
    box-shadow: 0 0 63px 5px rgba(92, 5, 11, 0.19); }
  &--yellow {
    background-color: $yellow;
    border-radius: 32px;
    padding: 21px 32px;
    font-size: 18px;
    color: $red2; }
  &--red-dark {
    background-color: transparent;
    border-radius: 32px;
    border: 1px solid $red5;
    padding: 17px 32px;
    font-size: 18px;
    color: $red5;
    transition: color 0.3s, border-color 0.3s;
    @include hover {
      color: $red4;
      border-color: $red4; } } }
.connect-btn {
  display: flex;
  padding:  19px;
  align-items: center;
  border-radius: 32px;
  border: 1px solid $blue;
  transition: border-color 0.3s, color 0.3s;
  @include md {
    display: inline-flex; }
  i {
    margin-right: 7px;
    font-size: 0;
    line-height: 1; }
  svg {
    font-size: 17px;
    fill: $blue;
    transition: fill 0.3s; }
  @include hover {
    border-color: $red4;
    color: $red4;
    svg {
      fill: $red4; } } }
.user-btn {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid $blue;
  font-size: 0;
  transition:  color 0.3s;
  i {
    display: block;
    @include vcenter; }
  svg {
    font-size: 15px;
    fill: $blue;
    transition: fill 0.3s; }
  @include hover {
    border-color: $red4;
    svg {
      fill: $red4; } } }
