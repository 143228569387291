.icon-arrow-down {
  width: 1.6em;
  height: 1em;
  fill: initial;
}
.icon-bike {
  width: 1.33em;
  height: 1em;
  fill: initial;
}
.icon-box {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-calendar {
  width: 0.9em;
  height: 1em;
  fill: initial;
}
.icon-call {
  width: 1.08em;
  height: 1em;
  fill: initial;
}
.icon-car {
  width: 1.47em;
  height: 1em;
  fill: initial;
}
.icon-car2 {
  width: 1.98em;
  height: 1em;
  fill: initial;
}
.icon-chair {
  width: 0.9em;
  height: 1em;
  fill: initial;
}
.icon-chat {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-circle-check {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-client-back {
  width: 1.02em;
  height: 1em;
  fill: initial;
}
.icon-clock {
  width: 0.68em;
  height: 1em;
  fill: initial;
}
.icon-clock2 {
  width: 1.21em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-coins {
  width: 1.39em;
  height: 1em;
  fill: initial;
}
.icon-connect-hands {
  width: 1.59em;
  height: 1em;
  fill: initial;
}
.icon-egor-head {
  width: 0.86em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-gaz {
  width: 1.46em;
  height: 1em;
  fill: initial;
}
.icon-ico_fast {
  width: 1.07em;
  height: 1em;
  fill: initial;
}
.icon-ico_fiz {
  width: 0.82em;
  height: 1em;
  fill: initial;
}
.icon-ico_jur {
  width: 0.82em;
  height: 1em;
  fill: initial;
}
.icon-ico_route {
  width: 0.97em;
  height: 1em;
  fill: initial;
}
.icon-insurance {
  width: 0.83em;
  height: 1em;
  fill: initial;
}
.icon-inventarization {
  width: 1.04em;
  height: 1em;
  fill: initial;
}
.icon-label {
  width: 0.82em;
  height: 1em;
  fill: initial;
}
.icon-location {
  width: 0.71em;
  height: 1em;
  fill: initial;
}
.icon-location2 {
  width: 1.01em;
  height: 1em;
  fill: initial;
}
.icon-mind {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-money {
  width: 1.43em;
  height: 1em;
  fill: initial;
}
.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-presentation {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-products {
  width: 0.81em;
  height: 1em;
  fill: initial;
}
.icon-rocket {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-route {
  width: 1.01em;
  height: 1em;
  fill: initial;
}
.icon-rubble {
  width: 0.81em;
  height: 1em;
  fill: initial;
}
.icon-safe {
  width: 1.02em;
  height: 1em;
  fill: initial;
}
.icon-smile {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-speedometer {
  width: 0.94em;
  height: 1em;
  fill: initial;
}
.icon-status {
  width: 1.06em;
  height: 1em;
  fill: initial;
}
.icon-twitter {
  width: 1.2em;
  height: 1em;
  fill: initial;
}
.icon-user {
  width: 0.93em;
  height: 1em;
  fill: initial;
}
.icon-viber {
  width: 0.96em;
  height: 1em;
  fill: initial;
}
.icon-vk {
  width: 1.58em;
  height: 1em;
  fill: initial;
}
.icon-walk {
  width: 1.44em;
  height: 1em;
  fill: initial;
}
