.know {
  margin-bottom: 350px;
  @include md {
    margin-bottom: 200px; }
  &__waves {}
  &__inner {
    display: flex;
    align-items: center;
    @include md {
      display: block; } }
  &__left {
    width: 275px;
    flex-shrink: 0;
    @include md {
      width: 100%;
      max-width: 570px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 88px;
      display: flex; }
    @include xxs {
      display: block; }
    &-text {
      text-align: right;
      @include xxs {
        text-align: center; }
      &:nth-of-type(2) {
        @include md {
          margin-left: 26px;
          font-size: 30px;
          text-align: left; }
        @include xxs {
          font-size: 18px;
          margin-left: 0;
          text-align: center; } } }
    .text {
      &--spec-22 {
        @include sm {
          line-height: 1.1; }
        strong {
          @include xxs {
            display: block; } } } } }
  &__right {
    width: 925px;
    padding-left: 100px;
    flex-shrink: 1;
    @include ld {
      padding-left: 70px; }
    @include md {
      padding-left: 0;
      width: 100%;
      min-height: 322px; }
    @include sm {
      min-height: 313px; }
    @include xxs {
      min-height: 1px; } } }

.know-block {
  position: relative;
  max-width: percentage(630/825);
  padding-top: 44px;
  padding-bottom: 62px;
  padding-left: percentage(104/630);
  @include ld {
    padding-left: 60px; }
  @include md {
    padding: 80px 5px 0;
    margin: 0 auto;
    width: 100%;
    max-width: 658px;
    text-align: center; }
  @include sm {
    max-width: 500px; }
  &__woman {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    @include md {
      left: 50%;
      top: 0; }
    svg {
      width: 88px;
      height: 123px; } }
  &__content {
    @include md {
      margin-bottom: 53px; }
    @include sm {
      margin-bottom: 57px; }
    @include xxs {
      margin-bottom: 40px; }
    .text {
      @include md {
        span {
          display: inline; } }
      &--spec-72 {
        @include sm {
          font-size: 57px; }
        @include xxs {
          font-size: 33px; } } } }
  &__btn {
    position: absolute;
    min-width: 286px;
    left: 100%;
    top: 50%;
    transform: translate(-74px, -50%);
    @include ld {
      left: auto;
      right: 0;
      transform: translate(150px, -50%); }
    @include md {
      position: static;
      transform: none; } }
  &__line {
    left: 0;
    width: 100%;
    @include md {
      width: 250px; }
    @include sm {
      width: 173px; }
    @include xxs {
      display: none; }
    &--top {
      top: 0;
      @include md {
        top: -20px; } }
    &--bottom {
      bottom: 0;
      @include md {
        left: auto;
        right: 0;
        bottom: auto;
        top: -20px; } } } }


