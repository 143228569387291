.time {
  position: relative;
  margin-bottom: 284px;
  @include lm {
    margin-bottom: 240px; }
  @include md {
    margin-bottom: 200px; }
  @include xxs {
    margin-bottom: 180px; }
  &__bg-wave {
    height: 1677px;
    top: 45%;
    transform: translateY(-50%);
    @include lm {
      height: 1500px; }
    @include md {
      top: 47%; }
    @include sm {
      display: none;
      height: 2000px; }
    @include xxs {
      height: 1400px; } } }

