.hero {
  &__wrap {
    position: relative;
    z-index: 1;
    height: 950px;
    @include l {
      height: 740px; } }
  &__bg-wrap {
    position: relative;
    width: 100%;
    height: 1150px;
    overflow: hidden;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(to top, $hero-bg-bottom, $hero-bg);
    @include gpu;
    margin-bottom: -1150px;
    pointer-events: none;
    @include lm {
      min-width: 1500px;
      left: 50%;
      margin-left: -750px;
      height: 900px;
      margin-bottom: -900px; }
    @include xxs {
      height: 750px;
      margin-bottom: -750px; } }
  &__bg {
    width: 100%;
    height: 1150px;
    @include lm {
      height: 1250px; }
    @include md {
      height: 1150px; }
    @include phone-css {
      opacity: 0; } }
  &__clouds {
    position: absolute;
    left: 50%;
    top: -4%;
    transform: translateX(-50%);
    width: 104%;
    height: auto;
    z-index: 0;
    @include lm {
      width: 104vw;
      min-width: 768px; } }
  &__plan {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto;
    &--1 {
      max-width: 100%;
      width: auto;
      z-index: 3;
      bottom: -10%;
      @include l {
        bottom: 13%; }
      @include xxlmin {
        bottom: -16%; } }
    &--2 {
      z-index: 2;
      width: 102%;
      bottom: -6%;
      @include l {
        bottom: 20%;
        width: 116%; }
      @include xlmin {
        bottom: -4%; }
      @include xxlmin {
         bottom: -44%; } }
    &--3 {
      z-index: 1;
      width: 102%;
      bottom: -4%;
      @include l {
        bottom: 22%;
        width: 116%; }
      @include xlmin {
        bottom: -1%; }
      @include xxlmin {
         bottom: -34%; } } }
  &__content {
    position: relative;
    z-index: 2;
    margin-top: 109px;
    @include md {
      margin-top: 130px; }
    @include sm {
      margin-top: 100px; } }

  &__inner {
    position: relative;
    z-index: 2;
    padding: 53px 0 0;
    @include l {
      padding: 38px 0 0; }
    @include xxs {
      padding: 10px 0 0; } }
  &__cols {
    margin-bottom: 38px;
    @include xxs {
      margin-bottom: 20px; } }
  &__man {
    position: absolute;
    left: -75px;
    top: -65px;
    z-index: 1;
    pointer-events: none;
    @include l {
      left: -15px;
      top: -50px; }
    @include lm {
      left: auto;
      right: 55%;
      top: 10px; }
    @include md {
      top: -10px;
      right: 52%; }
    @include sm {
      top: 10px;
      right: 44%; }
    @include xxs {
      top: -10px;
      right: 39%; }
    @include xxxs {
      right: 30%; }
    svg {
      width: 639px;
      height: 999px;
      @include l {
        width: 561px;
        height: 887px; }
      @include lm {
        width: 450px;
        height: 729px; }
      @include md {
        width: 500px;
        height: 750px; }
      @include xxs {
        width: 450px;
        height: 729px; } } }

  &__col {
    margin-left: 50%;
    @include lm {
      width: 510px;
      margin-left: 48%; }
    @include md {
      width: 47%;
      margin-left: 53%; }
    @include sm {
      width: 50%;
      margin-left: 50%; }
    @include xxxs {
      margin-left: 45%;
      width: 55%; } }
  &__title {
    margin-bottom: 10px;
    span {
      display: none;
      font-size: 20px;
      font-weight: normal;
      @include ireg;
      line-height: 1.2;
      @include l {
        display: inline-block;
        max-width: 268px; }
      @include lm {
        max-width: 230px;
        font-size: 16px; }
      @include md {
        max-width: 100%;
        text-transform: none;
        font-size: 22px;
        margin-top: 10px; }
      @include sm {
        font-size: 20px; }
      @include xxs {
        font-size: 18px; }
      @include xxxs {
        font-size: 15px; } } }
  &__text {
    max-width: 320px;
    font-size: 24px;
    @include ireg;
    color: $white;
    @include l {
      display: none; } }
  &__form {
    margin-bottom: 80px;
    @include l {
      margin-bottom: 36px; }
    &-line {} }
  &__mouse-btn {
    display: inline-block;
    vertical-align: top;
    appearance: none;
    border: none;
    background: none;
    @include md {
      display: none; }
    svg {
      fill: $white;
      width: 34px;
      height: 60px; } }
  &__mob-image {
    display: none;

    @include is-phone {
      position: relative;
      display: block;
      left: 50%;
      transform: translateX(-50%); } }
  .waves {
    height: 800px;
    transform: translateY(59%);
    @include l {
      transform: translateY(67%); }
    @include md {
      transform: translateY(76%); }
    @include xxs {
      transform: translateY(64%); }
    @include is-phone {
      canvas {
        display: none; } }
    &__inner {
      @include is-phone; } }

  .mouse-arrow {
    transform-origin: 50% 50%;
    animation: mouse-arrow 2s infinite; }
  .selectric {
    .button {
      display: none; } } }
@keyframes mouse-arrow {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  25% {
    opacity: 1;
    transform: translateY(-10px); }
  75% {
    transform: translateY(0px);
    opacity: 1; }
  100% {
    transform: translateY(0px);
    opacity: 0; } }
.js-hero-content-elem {
  opacity: 0;
  @include phone-css {
    opacity: 1; } }
.js-hero-bg, .js-hero-content-elem {
  &.is-visible {
    opacity: 1; } }
.js-custom-parallax {
  @include gpu; }
