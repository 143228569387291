.ready {
  margin-bottom: 291px;
  padding-bottom: 90px;
  @include md {
    margin-bottom: 240px;
    padding-bottom: 0; }
  &__waves {}
  &__inner {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @include lm {
      max-width: 984px;
      margin-left: auto;
      margin-right: auto; }
    @include md {
      display: block;
      max-width: 660px; } }
  &__text-wrap {
    position: relative;
    width: 600px;
    @include lm {
      width: 505px; }
    @include md {
      width: 100%;
      margin-bottom: 38px; }
    @include xxs {
      text-align: center; } }
  &__big-text {
    @include md {
      margin-bottom: 20px;
      font-size: 65px; }
    @include sm {
      font-size: 60px; }
    small {
      @include lm {
        max-width: 225px; }
      @include md {
        font-size: 26px;
        max-width: 280px; }
      @include sm {
        font-size: 24px;
        max-width: 242px; }
      @include xxs {
        max-width: 100%;
        margin-top: 10px;
        font-size: 20px; } } }
  &__form-wrap {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 600px;
    padding-left: 50px;
    @include md {
      width: 100%;
      padding: 0; } }
  &__car {
    position: absolute;
    left: 108%;
    top: 0px;
    @include lm {
      top: -20px; }
    @include md {
      left: auto;
      top: auto;
      right: 0;
      bottom: 0; }
    @include sm {
      position: static;
      margin-bottom: 20px;
      text-align: center; }
    svg {
      width: 135px;
      height: 92px;
      fill: $white;
      @include sm {
        width: 115px;
        height: 78px; } } }
  &__line {
    top: calc(100% + 15px);
    left: 146px;
    width: 624px;
    @include lm {
      left: 112px; }
    @include md {
      display: none; } }
  &__form {
    position: relative;
    @include md {
      margin-left: auto;
      margin-right: auto; } }
  .form {
    @include md {
      text-align: center; }
    &__line {
      @include md {
        display: block; } }
    &__field {
      @include md {
        margin-right: 0;
        margin-bottom: 38px; }
      @include xxs {
        width: 100%; } } }
  .text {
    &--spec-36 {
      @include md {
        max-width: 500px;
        font-size: 36px; }
      @include xxs {
        max-width: 100%;
        font-size: 26px; } } } }
