.number-plate {
  $this: &;
  position: relative;
  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @include sm {
      display: block; } }
  &__block {
    width: 33.33333%;
    padding-right: 25px;
    margin-bottom: 60px;
    @include md {
      width: 50%;
      margin-bottom: 50px; }
    @include sm {
      width: 100%;
      margin-bottom: 30px;
      padding-right: 0; } }
  &__imgs {
    position: absolute;
    z-index: -2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    @include md {
      top: 25%; }
    @include sm {
      top: 45%; }
    img {
      position: absolute;
      @include vcenter;
      transition: opacity 0.5s;
      opacity: 0;
      max-width: 100vw;
      height: auto;
      @include lm {
        width: 1100px;
        max-width: none; }
      &.is-active {
        opacity: 1; } } }
  &--how-work {
    #{$this}__inner {
      @include md {
        justify-content: space-between;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto; }
      @include sm {
        max-width: 100%; } }
    #{$this}__block {
      @include md {
        width: 44%;
        padding: 0; }
      @include sm {
        width: 100%; } } } }
.number-block {
  $this: &;
  position: relative;
  display: flex;
  max-width: 300px;
  cursor: pointer;
  @include md {
    max-width: 100%; }
  &__number {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 54px;
    min-width: 40px;
    color: $red4;
    font-size: 60px;
    padding-right: 16px;
    line-height: 0.8;
    @include ilight;
    @include sm {
      min-height: 36px;
      font-size: 45px; }
    &:before {
      content: '';
      position: absolute;
      left: -8px;
      right: -0px;
      bottom: -3px;
      top: -3px;
      background: $white;
      z-index: 0;
      border-radius: 4px;
      transition: opacity 0.5s;
      opacity: 0;
      @include md {
        top: -5px;
        bottom: -5px; } }
    span {
      position: relative;
      z-index: 1;
      top: 3px;
      @include is-mac {
        top: 8px; }
      @include is-touch {
        top: 6px; } } }
  &__text-wrap {
    position: relative;
    flex-grow: 1;
    min-height: 54px;
    @include sm {
      min-height: 36px; } }
  &__real-text {
    position: relative;
    visibility: hidden; }
  &__view-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    div {
      position: relative;
      display: inline-block;
      vertical-align: top;
      height: 18px;
      &:before {
        content: '';
        position: absolute;
        left: -10px;
        right: -6px;
        bottom: -3px;
        top: -3px;
        background: $white;
        z-index: 0;
        border-radius: 0 4px 4px 0;
        opacity: 0;
        transition: opacity 0.5s;
        @include md {
          top: -5px;
          bottom: -5px; } }
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 70%;
        box-shadow: 0 -10px 30px rgba(90, 53, 75, 0.1), 0 10px 30px rgba(90, 53, 75, 0.1), -70px -10px 30px rgba(90, 53, 75, 0.1), -70px 10px 30px rgba(90, 53, 75, 0.1);
        z-index: -1;
        transition: opacity 0.5s;
        opacity: 0; }
      &:first-of-type {}
      span {
        position: relative;
        z-index: 1; } } }
  &--max-320 {
    max-width: 320px;
    @include md {
      max-width: 100%; } }
  &.is-active {
    #{$this}__view-text {
      div {
        &:before, &:after {
          opacity: 1; } } }
    #{$this}__number {
      &:before {
        opacity: 1; } } }
  &.is-hidden {
    opacity: 0;
    @include is-phone {
      opacity: 1; } } }
.number-block-text-stile {
  font-size: 15px;
  line-height: 18px; }
