.better {
  position: relative;
  &__inner {}
  &__title {}
  &__tabs {} }
.better-list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .better-block {
    width: 33.3333%;
    @include md {
      width: 50%; }
    @include sm {
      width: 100%; } } }
.better-block {
  display: flex;
  margin-bottom: 82px;
  @include md {
    margin-bottom: 48px; }
  &__icon {
    width: 67px;
    flex-grow: 0;
    flex-shrink: 0;
    @include sm {
      width: 52px; }
    svg {
      fill: $red4;
      font-size: 46px;
      line-height: 1;
      @include sm {
        font-size: 40px; } } }
  &__inner {
    padding-right: 10px;
    max-width: 295px;
    @include md {
      padding-right: 20px; }
    @include sm {
      padding-right: 0;
      max-width: 100%; } }
  &__head {
    @include iblack;
    text-transform: uppercase;
    margin-bottom: 8px; }
  &__text {
    font-size: 15px; }
  &.js-better-list-item {
    opacity: 0; } }
