.sub-title {
  color: $red3;
  font-size: 24px;
  line-height: 1;
  text-transform: uppercase;
  @include ireg;
  @include xxs {
    font-size: 18px; }
  strong {
    @include iblack; }
  &--center {
    text-align: center; }
  &--mb-s {
    margin-bottom: 28px; }
  &--mb-m {
    margin-bottom: 36px; }
  &--mb-lg {
    margin-bottom: 60px; }
  &--lowercase {
    text-transform: none; } }
