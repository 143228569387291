body {
	background:	$white;
	color: $black-main;
	font-size: 18px;
	line-height: 1.33;
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	@include smooth-font;
	@include mlight;
	@include xxs {
		font-size: 16px; }
	&.is-modal-open {
		overflow: hidden;
		position: fixed;
		width: 100%; } }

h1, h2, h3, h4, h5, h6, strong {
	font-style: normal; }

a {
	text-decoration: none; }
.out {
	overflow: hidden; }
.center {
	text-align: center; }
.link {
	color: $red4;
	@include mreg; }
.article {
	font-size: 16px;
	line-height: 1.4;
	@include xxs {
		font-size: 14px; }
	p {
		&:not(:last-of-type) {
			margin-bottom: 22px; } } }
.arrow-anim {
	position: absolute; }
.is-hidden {
	display: none; }
.icon-location {
	transition: fill 0.3s;
	@include hover {
		fill: #b42028; } }
.js-lazy {
	transition: opacity 0.4s;
	opacity: 1;
	&[data-src] {
		opacity: 0; } }


.js-anim-elem {
	opacity: 0;
	@include md {
		opacity: 1; }
	&[data-animation="arrow"] {
		opacity: 0;
		font-size: 0;
		overflow: hidden;
		img {
			width: 100%;
			height: auto;
			@include md {
				height: 100%; } } } }
.js-logo-scene-item, .js-who-title, .js-manager-icon-smile {
	opacity: 0; }

body {
	@include is-touch {
		.js-tablet-anim-elem {
			opacity: 0;
			@include mdmin {
				opacity: 1; }
			.js-anim-elem {
				@include mdmin {
					opacity: 1; } } } } }
.js-anim-elem, .js-tablet-anim-elem {
	opacity: 0; }
.section {
	&.is-animated {
	  .js-anim-elem, .js-tablet-anim-elem {
	    opacity: 1; } } }

.is-phone {
	.section {
		.js-tablet-anim-elem {
			opacity: 1; } } }
.js-who-title, .js-anim-elem, .js-tablet-anim-elem, .js-logo-scene-item, .js-hero-content-elem, .js-who-title, .js-manager-icon-smile {
	@include is-phone {
		opacity: 1; } }
.js-manager-icon-sad {
	@include is-phone {
		opacity: 0; } }
