.l {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  @include xxxs {
    padding:  0 12px; }
  &--sm {
    max-width: 1170px; }
  &--xs {
    max-width: 1140px; } }
.cols {
  display: flex;
  flex-wrap: wrap; }
.col {
  &--50 {
    width: 50%; }
  &--left {
    padding-right: 30px;
    @include xxs {
      padding: 0; } }
  &--right {
    padding-left: 30px;
    @include xxs {
      padding: 0; } } }
