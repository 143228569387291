.modal {
	@include coverdiv;
	z-index: 999;
	position: fixed;
	transition: opacity 0.3s ease-in-out;
	&:not(.is-open) {
		opacity: 0;
		visibility: hidden;
		z-index: -999; }

	&__container {
		position: relative;
		display: flex;
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: auto; }

	&__overlay {
		@include coverdiv;
		position: fixed;
		&:before {
			content: '';
			@include coverdiv;
			display: block;
			background: rgba($white, 0.95);
			@include l {
				max-width: 100%;
				height: 100%;
				padding: 0; } } }


	&__inner {
		position: relative;
		padding: 1em 0;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		@include xs {
			width: 100%; } }
	&__close {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 46px;
		min-height: 46px;
		line-height: 0;
		margin-bottom: 1.5em;
		border-radius: 50%;
		background-color: #ee2025;
		font-size: 20px;
		color: $white;
		.icon {
			line-height: 0; }
		@include xxs {
			width: 30px;
			min-height: 30px;
			font-size: 14px; } }

	&__content {
		flex-grow: 1;
		width: 100%;
		max-width: 850px;
		padding: 40px 60px 80px;
		box-shadow: 0 0 40px rgba(48, 48, 48, 0.19);
		background-color: $white;

		&--xl {
			max-width: 920px;
			padding: 40px 60px 50px; }

		@include sm {
			max-width: 700px;
			padding: 40px 40px 80px; }
		@include xs {
			max-width: 520px; }
		@include xxxs {
			padding: 15px 15px 30px; } }

	&__scrollbox {
		max-height: 350px;
		overflow-y: auto;
		margin-right: -30px;
		padding-right: 30px;
		margin-bottom: -20px;
		@include mreg;
		font-size: 16px;

		p + p {
			margin-top: 1em; } }


	&__map {
		max-width: 800px;
		width: 100%;
		height: 344px;
		background-color: #eee;
		border-radius: 7px;
		border: 1px solid $gray5;
		overflow: hidden; } }

.balloon-content {
	@include mreg;
	font-size: 14px;
	color: $balloonTextColor; }
.balloon-link {
	position: relative;
	display: inline-block;
	font-size: 12px;
	color: $balloonTextColor;
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		border-bottom: 1px dashed $balloonTextColor; }

	@include hover {
		color: $black; } }
