@include font(Intro-Light, Intro-Light);
@include font(Intro-Regular, Intro-Regular);
@include font(Intro-Bold, Intro-Bold);
@include font(Intro-Black, Intro-Black);

@include font(MuseoCyrl-300, MuseoCyrl-300);
@include font(MuseoCyrl-500, MuseoCyrl-500);
@include font(MuseoCyrl-700, MuseoCyrl-700);


@mixin ilight {
  font-family: Intro-Light, Arial, Helvetica, sans-serif; }
@mixin ireg {
  font-family: Intro-Regular, Arial, Helvetica, sans-serif; }
@mixin ibold {
  font-family: Intro-Bold, Arial, Helvetica, sans-serif; }
@mixin iblack {
  font-family: Intro-Black, Arial, Helvetica, sans-serif; }
@mixin mlight {
  font-family: MuseoCyrl-300, Arial, Helvetica, sans-serif; }
@mixin mreg {
  font-family: MuseoCyrl-500, Arial, Helvetica, sans-serif; }
@mixin mbold {
  font-family: MuseoCyrl-700, Arial, Helvetica, sans-serif; }
