.pre-footer {
  margin-bottom: 300px;
  @include md {
    margin-bottom: 270px; }
  @include sm {
    margin-bottom: 250px; }
  @include xxs {
    margin-bottom: 170px; }
  &__inner {}
  &__sub-title {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    @include md {
      font-size: 24px; }
    @include xxs {
      font-size: 18px; }
    span {
      @include sm {
        display: block; }
      &:not(last-of-type) {
        margin-bottom: 25px; } } }
  .cols {
    @include sm {
      display: block; } }
  .col {
    &--50 {
      @include sm {
        width: 100%;
        padding: 0; } } } }
