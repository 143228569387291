.text {
  line-height: 1.2;
  color: $white;
  &--spec {
    text-transform: uppercase;
    @include ilight;
    strong {
      font-weight: normal;
      @include iblack; }
    span {
      display: block;
      @include xxs {
        display: inline; } }
    &-22 {
      font-size: 22px;
      @include lm {
        font-size: 20px; }
      @include xxs {
        font-size: 18px; } }
    &-36 {
      font-size: 36px;
      line-height: 1;
      @include lm {
        font-size: 28px; }
      @include sm {
        font-size: 33px; }
      @include xxs {
        font-size: 28px; } }
    &-62 {
      font-size: 62px;
      @include iblack;
      line-height: 0.9;
      @include lm {
        font-size: 58px;
        line-height: 1.33; }
      @include md {
        font-size: 65px;
        line-height: 1; } }
    &-65 {
      font-size: 65px;
      @include iblack;
      line-height: 0.9;
      @include lm {
        font-size: 52px; }
      @include sm {
        font-size: 50px; }
      @include xxs {
        font-size: 29px; }
      span {
        display: inline-block; }
      small {
        display: inline-block;
        vertical-align: top;
        max-width: 310px;
        font-size: 26px;
        line-height: 1.1;
        @include ilight;
        @include lm {
          max-width: 260px;
          font-size: 21px; }
        @include sm {
          font-size: 26px; }
        @include xxs {
          font-size: 20px; } } }
    &-72 {
      line-height: 0.82;
      @include iblack; }
    &-72-light {
      line-height: 1;
      @include ilight;
      strong {
        @include iblack; } }
    &-72, &-72-light {
      font-size: 72px;
      @include lm {
        font-size: 60px; }
      @include md {
        font-size: 72px; }
      @include sm {
        font-size: 60px; }
      @include xxs {
        font-size: 40px; } } }
  &--right {
    text-align: right; }
  &--mb {
    &-10 {
      margin-bottom: 10px; }
    &-16 {
      margin-bottom: 16px; }
    &-28 {
      margin-bottom: 28px; } } }
