.selectric-wrapper {
  position: relative;
  cursor: pointer;
  height: 100%;
  z-index: 10; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border: 1px solid #e8e8e8;
  background: #fff;
  position: relative;
  height: 100%;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 10px 50px 10px 30px;
  box-shadow: 0 2px 4px transparent;
  transition: box-shadow 0.3s;
  z-index: 10;
  @include xxs {
    padding: 10px 30px 10px 15px; }
  .label {
    display: block;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #959495;
    font-size: 16px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }



  .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 14px;
    height: 8px;
    right: 32px;
    top: 50%;
    margin-top: -2px;
    @include md {
      right: 20px; }

    font-size: 0;
    &:after {
      content: '';
      display: block;
      width: 14px;
      height: 8px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      background-image: url('../img/select-arrow.svg');
      transition: transform 0.3s;
      transform-origin: 50% 50%; } } }

.selectric-open {
  .button {
    &:after {
      transform: rotateZ(180deg); } }
  .selectric {
    box-shadow: 0 2px 4px rgba(0,0,0,.1); }

  .selectric-items {
    display: block; } }

.selectric-disabled {
  filter: alpha(opacity = 50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%; }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; } } }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */

.selectric-items {
  display: none;
  position: absolute;
  top: calc(100% - 6px);
  padding-top: 6px;
  left: 0;
  background: #fff;
  z-index: 9;


  .selectric-scroll {
    height: 100%;
    overflow: auto; } }

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%; }

.selectric-items {
  border-radius: 0 0 7px 7px;
  overflow: hidden;
  box-shadow: 0 20px 49px rgba(0,0,0,.2);
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    min-height: 20px; }


  li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    display: block;
    padding: 18px 40px 18px 30px;
    color: #959495;
    cursor: pointer;

    transform: background-color 0.3s;
    @include xxs {
      padding: 15px 10px 15px 15px; }
    &.selected {

      color: #383737; }


    @include hover {
      background: #f6f6f6; } }


  .disabled {
    filter: alpha(opacity = 50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

  .selectric-group {
    .selectric-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: none;
      color: #444; }

    &.disabled li {
      filter: alpha(opacity = 100);
      opacity: 1; }

    li {
      padding-left: 25px; } } }
